import { constants } from "@features/utils/constants";
import * as Yup from "yup";

const REQUIRED_PROPERTIES_BY_CATEGORY = {
  animal_model: {
    name: "Strain name is required",
    category: "Resource category is required",
    contact_user_id: "Contact user ID is required",
    lab_id: "Lab ID is required",
    species_name: "Species name is required"
  },
  cell_line: {
    name: "Name of cell line is required",
    species_name: "Species name is required",
    tissue_name: "Tissue name is required"
  }
};

const createResourceValidationSchema = (skipValidation: boolean) =>
  Yup.object({
    category: Yup.string().required("Resource category is required"),
    source: Yup.string().required(
      "Please specify if you are sharing a resource or submitting a request"
    ),
    jax_external_id: Yup.mixed()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .when(["category", "databaseSelection"], {
        is: (category: string, databaseSelection: string) => {
          return (
            category === constants.animalModel &&
            databaseSelection === constants.jax
          );
        },
        then: Yup.mixed()
          .nullable()
          .required("JAX ID is required when JAX database is selected."),
        otherwise: Yup.mixed().nullable()
      }),
    mgi_external_id: Yup.mixed()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .when(["category", "databaseSelection"], {
        is: (category: string, databaseSelection: string) => {
          return (
            category === constants.animalModel &&
            databaseSelection === constants.mgi
          );
        },
        then: Yup.mixed()
          .nullable()
          .required("MGI ID is required when MGI database is selected."),
        otherwise: Yup.mixed().nullable()
      }),
    strainName: Yup.string().when("category", {
      is: constants.animalModel,
      then: skipValidation
        ? Yup.string()
        : Yup.string().required(
            REQUIRED_PROPERTIES_BY_CATEGORY.animal_model.name
          )
    }),
    synonyms: Yup.string().when("category", {
      is: constants.animalModel,
      then: Yup.string()
    }),
    mutation_type_names: Yup.array().when("category", {
      is: constants.animalModel,
      then: Yup.array()
    }),
    target_gene_names: Yup.array().when("category", {
      is: constants.animalModel,
      then: Yup.array()
    }),
    species_name: Yup.string().when("category", {
      is: constants.animalModel,
      then: Yup.string().required(
        REQUIRED_PROPERTIES_BY_CATEGORY.animal_model.species_name
      ),
      otherwise: Yup.string().nullable()
    }),
    cellLineDBSelect: Yup.string().when("category", {
      is: constants.cellLine,
      then: Yup.string().required("Database selection is required")
    }),
    atcc_external_id: Yup.mixed()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .when(["category", "cellLineDBSelect"], {
        is: (category: string, cellLineDBSelect: string) => {
          return category === constants.cellLine && cellLineDBSelect === "atcc";
        },
        then: Yup.mixed()
          .nullable()
          .required("ATCC ID is required when ATCC database is selected."),
        otherwise: Yup.mixed().nullable()
      }),
    cellLineName: Yup.string().when(["category", "cellLineDBSelect"], {
      is: (category: string, cellLineDBSelect: string) => {
        return (
          category === constants.cellLine && cellLineDBSelect === "manual_entry"
        );
      },
      then: Yup.string().required(
        REQUIRED_PROPERTIES_BY_CATEGORY.cell_line.name
      ),
      otherwise: Yup.string()
    }),
    cellLineSpeciesName: Yup.string().when(["category", "cellLineDBSelect"], {
      is: (category: string, cellLineDBSelect: string) => {
        return (
          category === constants.cellLine && cellLineDBSelect === "manual_entry"
        );
      },
      then: Yup.string().required(
        REQUIRED_PROPERTIES_BY_CATEGORY.cell_line.species_name
      ),
      otherwise: Yup.string()
    }),
    tissue_name: Yup.string().when(["category", "cellLineDBSelect"], {
      is: (category: string, cellLineDBSelect: string) => {
        return (
          category === constants.cellLine && cellLineDBSelect === "manual_entry"
        );
      },
      then: Yup.string().required(
        REQUIRED_PROPERTIES_BY_CATEGORY.cell_line.tissue_name
      ),
      otherwise: Yup.string()
    }),
    disease_name: Yup.string().when(["category", "cellLineDBSelect"], {
      is: (category: string, cellLineDBSelect: string) => {
        return (
          category === constants.cellLine && cellLineDBSelect === "manual_entry"
        );
      },
      then: Yup.string(),
      otherwise: Yup.string()
    }),
    mutation_names: Yup.string().when("category", {
      is: constants.cellLine,
      then: Yup.string()
    }),
    storage_condition_names: Yup.mixed().when("category", {
      is: constants.cellLine,
      then: Yup.mixed()
    }),
    morphology: Yup.string().when(["category", "cellLineDBSelect"], {
      is: (category: string, cellLineDBSelect: string) => {
        return (
          category === constants.cellLine && cellLineDBSelect === "manual_entry"
        );
      },
      then: Yup.string(),
      otherwise: Yup.string()
    }),
    title: Yup.string().required("Title is required"),
    description: Yup.string()
  });

const addResourceValidationSchema = (skipValidation: boolean) =>
  Yup.object({
    category: Yup.string().required("Resource category is required"),
    source: Yup.string().required(
      "Please specify if you are sharing a resource or submitting a request"
    ),
    jax_external_id: Yup.mixed()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .when(["category", "databaseSelection"], {
        is: (category: string, databaseSelection: string) => {
          return (
            category === constants.animalModel &&
            databaseSelection === constants.jax
          );
        },
        then: Yup.mixed()
          .nullable()
          .required("JAX ID is required when JAX database is selected."),
        otherwise: Yup.mixed().nullable()
      }),
    mgi_external_id: Yup.mixed()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .when(["category", "databaseSelection"], {
        is: (category: string, databaseSelection: string) => {
          return (
            category === constants.animalModel &&
            databaseSelection === constants.mgi
          );
        },
        then: Yup.mixed()
          .nullable()
          .required("MGI ID is required when MGI database is selected."),
        otherwise: Yup.mixed().nullable()
      }),
    strainName: Yup.string().when("category", {
      is: constants.animalModel,
      then: skipValidation
        ? Yup.string()
        : Yup.string().required(
            REQUIRED_PROPERTIES_BY_CATEGORY.animal_model.name
          )
    }),
    synonyms: Yup.string().when("category", {
      is: constants.animalModel,
      then: Yup.string()
    }),
    mutation_type_names: Yup.array().when("category", {
      is: constants.animalModel,
      then: Yup.array()
    }),
    target_gene_names: Yup.array().when("category", {
      is: constants.animalModel,
      then: Yup.array()
    }),
    species_name: Yup.string().when("category", {
      is: constants.animalModel,
      then: Yup.string().required(
        REQUIRED_PROPERTIES_BY_CATEGORY.animal_model.species_name
      )
    }),
    cellLineDBSelect: Yup.string().when("category", {
      is: constants.cellLine,
      then: Yup.string().required("Database selection is required")
    }),
    atcc_external_id: Yup.mixed()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .when(["category", "cellLineDBSelect"], {
        is: (category: string, cellLineDBSelect: string) => {
          return category === constants.cellLine && cellLineDBSelect === "atcc";
        },
        then: Yup.mixed()
          .nullable()
          .required("ATCC ID is required when ATCC database is selected."),
        otherwise: Yup.mixed().nullable()
      }),
    cellLineName: Yup.string().when(["category", "cellLineDBSelect"], {
      is: (category: string, cellLineDBSelect: string) => {
        return (
          category === constants.cellLine && cellLineDBSelect === "manual_entry"
        );
      },
      then: Yup.string().required(
        REQUIRED_PROPERTIES_BY_CATEGORY.cell_line.name
      ),
      otherwise: Yup.string()
    }),
    cellLineSpeciesName: Yup.string().when(["category", "cellLineDBSelect"], {
      is: (category: string, cellLineDBSelect: string) => {
        return (
          category === constants.cellLine && cellLineDBSelect === "manual_entry"
        );
      },
      then: Yup.string().required(
        REQUIRED_PROPERTIES_BY_CATEGORY.cell_line.species_name
      ),
      otherwise: Yup.string()
    }),
    tissue_name: Yup.string().when(["category", "cellLineDBSelect"], {
      is: (category: string, cellLineDBSelect: string) => {
        return (
          category === constants.cellLine && cellLineDBSelect === "manual_entry"
        );
      },
      then: Yup.string().required(
        REQUIRED_PROPERTIES_BY_CATEGORY.cell_line.tissue_name
      ),
      otherwise: Yup.string()
    }),
    disease_name: Yup.string().when(["category", "cellLineDBSelect"], {
      is: (category: string, cellLineDBSelect: string) => {
        return (
          category === constants.cellLine && cellLineDBSelect === "manual_entry"
        );
      },
      then: Yup.string(),
      otherwise: Yup.string()
    }),
    morphology: Yup.string().when(["category", "cellLineDBSelect"], {
      is: (category: string, cellLineDBSelect: string) => {
        return (
          category === constants.cellLine && cellLineDBSelect === "manual_entry"
        );
      },
      then: Yup.string(),
      otherwise: Yup.string()
    }),
    mutation_names: Yup.string().when("category", {
      is: constants.cellLine,
      then: Yup.string()
    }),
    storage_condition_names: Yup.mixed().when("category", {
      is: constants.cellLine,
      then: Yup.mixed()
    })
  });

export { addResourceValidationSchema, createResourceValidationSchema };
