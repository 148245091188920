import React from "react";

import { ResourceAutocomplete } from "@components/AutoComplete/ResourceAutocomplete";
import { optionsFilter } from "@features/utils/automplete-filter";
import { CreateResourceFormValues } from "@interfaces/create-resource-form";
import {
  FormikResourceValues,
  ResourceProperties
} from "@interfaces/resource-values";
import { Stack } from "@mui/material";
import { FormikProps } from "formik";

interface PlasmidFieldsProps {
  formik: FormikProps<CreateResourceFormValues | FormikResourceValues>;
  resourcePropsNames: ResourceProperties;
  loading: boolean;
  setResourcePropsValues: React.Dispatch<
    React.SetStateAction<ResourceProperties>
  >;
  resourcePropsValues: Record<string, string[]>;
}

const PlasmidFields: React.FC<PlasmidFieldsProps> = ({
  loading,
  resourcePropsNames,
  setResourcePropsValues,
  resourcePropsValues,
  formik
}) => {
  return (
    <Stack spacing={3}>
      <ResourceAutocomplete
        id={"name"}
        resourceNames={resourcePropsNames?.name || []}
        label={"Name of Plasmid"}
        value={formik.values.name || ""}
        loading={loading}
        requiredField
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            name: newValue
          });
          formik.setFieldValue("name", newValue?.toString() || "");
        }}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={(formik.touched.name && formik.errors.name) || ""}
      />
      <ResourceAutocomplete
        id={"add_gene_external_id"}
        resourceNames={resourcePropsNames?.add_gene_external_id || []}
        label={"AddGene ID (if known)"}
        value={formik.values.add_gene_external_id || ""}
        loading={loading}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            add_gene_external_id: newValue
          });
          formik.setFieldValue(
            "add_gene_external_id",
            newValue?.toString() || ""
          );
        }}
        error={
          formik.touched.add_gene_external_id &&
          Boolean(formik.errors.add_gene_external_id)
        }
        helperText={
          (formik.touched.add_gene_external_id &&
            formik.errors.add_gene_external_id) ||
          ""
        }
      />
      <ResourceAutocomplete
        id={"target_gene_names"}
        fullWidth
        multiple
        resourceNames={resourcePropsNames?.target_gene_names || []}
        label={"Target Gene(s)"}
        loading={loading}
        value={formik.values?.target_gene_names || []}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          const updatedValue = Array.isArray(newValue) ? newValue : [newValue];
          setResourcePropsValues({
            ...resourcePropsValues,
            target_gene_names: updatedValue
          });
          formik.setFieldValue("target_gene_names", updatedValue);
        }}
        error={
          formik.touched.target_gene_names &&
          Boolean(formik.errors.target_gene_names)
        }
        helperText={
          Array.isArray(formik.errors.target_gene_names)
            ? formik.errors.target_gene_names.join(", ")
            : formik.errors.target_gene_names || ""
        }
      />
      <ResourceAutocomplete
        id='storage_condition_names'
        multiple
        resourceNames={resourcePropsNames?.storage_condition_names || []}
        label={"Storage Condition"}
        loading={loading}
        optionsFilter={optionsFilter}
        value={formik.values.storage_condition_names || []}
        onChange={(_, newValue) => {
          const updatedValue = Array.isArray(newValue) ? newValue : [newValue];
          setResourcePropsValues({
            ...resourcePropsValues,
            storage_condition_names: updatedValue
          });
          formik.setFieldValue("storage_condition_names", updatedValue);
        }}
        error={
          formik.touched.storage_condition_names &&
          Boolean(formik.errors.storage_condition_names)
        }
        helperText={
          (formik.touched.storage_condition_names &&
            formik.errors.storage_condition_names) ||
          ""
        }
      />
    </Stack>
  );
};

export default PlasmidFields;
