/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  FormikResourceValues,
  initialAddDialogValues
} from "@interfaces/resource-values";

type InitialAddDialogValues = {
  [K in keyof typeof initialAddDialogValues]: (typeof initialAddDialogValues)[K] extends string[]
    ? string[] | string
    : (typeof initialAddDialogValues)[K];
};

export const transformToInitialValues = (
  input: FormikResourceValues,
  template: InitialAddDialogValues
): InitialAddDialogValues => {
  const transformed: Partial<InitialAddDialogValues> = {};

  // Iterating over template keys and populate the transformed object
  for (const key in template) {
    if (Object.prototype.hasOwnProperty.call(input, key)) {
      const typedKey = key as keyof InitialAddDialogValues;

      if (Array.isArray(template[typedKey])) {
        const templateValue = template[typedKey] as unknown[];
        (transformed as any)[typedKey] = Array.isArray(input[typedKey])
          ? (input[typedKey] as typeof templateValue)
          : templateValue;
      } else {
        // Handle string fields (non-array fields)
        (transformed as any)[typedKey] =
          input[typedKey] !== null
            ? (input[typedKey] as string)
            : template[typedKey];
      }
    } else {
      const typedKey = key as keyof InitialAddDialogValues;
      (transformed as any)[typedKey] = template[typedKey]; // Using template value if key is missing
    }
  }

  // Setting databaseSelection based on jax_external_id or mgi_external_id
  if (input.jax_external_id) {
    transformed.databaseSelection = "jax";
    transformed.databaseSelect = "jax";
  } else if (input.mgi_external_id) {
    transformed.databaseSelection = "mgi";
    transformed.databaseSelect = "mgi";
  } else if (input.atcc_external_id) {
    transformed.cellLineDBSelect = "atcc";
  } else {
    transformed.databaseSelection = template.databaseSelection;
    transformed.databaseSelect = template.databaseSelect;
  }

  return transformed as InitialAddDialogValues;
};
