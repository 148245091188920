import React, { ReactNode } from "react";

import clsx from "@features/utils/clsx";
import { Transition } from "@headlessui/react";
import useClickAway from "@hooks/useClickAway";

interface DashboardPopoutProps {
  active: boolean;
  children: ReactNode;
  className?: string;
  classNameContent?: string;
  onRequestClose: () => void;
}

const DashboardPopout: React.FC<DashboardPopoutProps> = ({
  active,
  children,
  className = "origin-top-right right-0",
  classNameContent,
  onRequestClose
}) => {
  const ref = useClickAway<HTMLDivElement>(onRequestClose, active);

  return (
    <Transition
      show={active}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
      className={clsx(
        "absolute mt-2 w-[85vw] max-w-sm rounded-md shadow-lg border border-gray-200",
        className
      )}
    >
      <div
        ref={ref}
        className={clsx(
          "rounded-md bg-white shadow-xs overflow-hidden",
          classNameContent
        )}
        role='menu'
        aria-orientation='vertical'
        aria-labelledby='user-menu'
      >
        {children}
      </div>
    </Transition>
  );
};

export default DashboardPopout;
