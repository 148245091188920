import { createFilterOptions } from "@mui/material/Autocomplete";

export const filter = createFilterOptions<string>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const optionsFilter = (options: string[], params: any) => {
  const filtered = filter(options, params);

  const { inputValue } = params;
  // Suggest the creation of a new value
  const isExisting = options.some((option) => inputValue === option);
  if (inputValue !== "" && !isExisting) {
    filtered.push(inputValue);
  }

  return filtered;
};
