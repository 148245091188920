import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useHandleSearchSubmit } from "@hooks/useHandleSearchSubmit";
import { useQuery } from "@hooks/useQuery";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";

interface SearchComponentProps {
  placeholder: string;
  onFetch: (query: string) => void;
  setQuery?: (query?: string) => void;
  isMessagingPage: boolean | null;
}

const SearchComponent: React.FC<SearchComponentProps> = ({
  placeholder,
  onFetch,
  setQuery,
  isMessagingPage
}) => {
  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const searchQuery = query.get("query") || "";

  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);
  const [showDropdown, setShowDropdown] = useState(false);
  const [suggestions, setSuggestions] = useState<
    { text: string; page: string }[]
  >([]);

  const updateQueryParams = (searchText: string) => {
    const params = new URLSearchParams(location.search);
    if (searchText) {
      params.set("query", searchText);
    } else {
      params.delete("query");
    }
    navigate({ search: params.toString() });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setLocalSearchQuery(searchText);
    setShowDropdown(searchText.length > 0);

    setSuggestions([
      {
        text: `${searchText} in Public feed`,
        page: "/dashboard/home"
      },
      {
        text: `${searchText} in Institution feed`,
        page: "/dashboard"
      },
      {
        text: `${searchText} in Institution database`,
        page: "/dashboard/institution-database"
      },
      {
        text: `${searchText} in Lab and People`,
        page: "/dashboard/institution-search"
      }
    ]);
  };

  const handleSearchSubmit = () => {
    updateQueryParams(localSearchQuery);
    onFetch(localSearchQuery);
  };

  useEffect(() => {
    if (setQuery) {
      setQuery(searchQuery);
    }
    onFetch(searchQuery);
  }, [dispatch, searchQuery]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setShowDropdown(false);
      handleSearchSubmit();
    }
  };
  const handlePostSearch = useHandleSearchSubmit(setLocalSearchQuery);
  const handleOptionClick = (page: string) => {
    if (
      page === "/dashboard/institution-database" ||
      page === "/dashboard/institution-search"
    ) {
      navigate(`${page}?query=${localSearchQuery}`);
    } else {
      handlePostSearch(localSearchQuery);
      navigate(`${page}`);
    }
    setShowDropdown(false);
  };

  const highlightText = (text: string) => {
    if (!localSearchQuery) return text;

    const parts = text.split(new RegExp(`(${localSearchQuery})`, "gi"));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === localSearchQuery.toLowerCase() ? (
            <Typography
              key={index}
              component='span'
              fontWeight='bold'
              style={{ margin: "0 2px" }}
            >
              {part}
            </Typography>
          ) : (
            <Typography
              key={index}
              component='span'
              style={{ margin: "0 2px" }}
            >
              {part}
            </Typography>
          )
        )}
      </>
    );
  };

  return (
    <Box bgcolor={APP_COLORS.white} position='relative'>
      <TextField
        fullWidth
        variant='outlined'
        value={localSearchQuery}
        onChange={handleSearchChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <IconButton sx={{ padding: 0 }}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      {/* Dropdown for suggestions */}
      {showDropdown && isMessagingPage !== true && (
        <Paper
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            zIndex: 10,
            backgroundColor: APP_COLORS.white,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
          }}
        >
          <List>
            {suggestions.map((suggestion, index) => (
              <ListItem
                key={index}
                button
                onClick={() => handleOptionClick(suggestion.page)}
              >
                <SearchIcon style={{ marginRight: "8px" }} />
                {highlightText(suggestion.text)}
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
};

export default SearchComponent;
