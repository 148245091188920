import { Button, ButtonProps, styled, Typography } from "@mui/material";

export const ShipporiTypography = styled(Typography)(() => ({
  fontFamily: '"Shippori-Mincho-B1", serif'
}));

export const CustomizedInviteButton = styled(Button)<ButtonProps>(() => ({
  textDecoration: "none",
  "&:hover": {
    background: "none",
    textDecoration: "underline"
  }
}));
