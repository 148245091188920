import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import MoreIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import about from "@static/icons/about.svg";
import home from "@static/icons/home.svg";
import messaging from "@static/icons/messaging.svg";
import notification from "@static/icons/notification.svg";

interface MobileMenuProps {
  isLoggedIn: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appConfig: any;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isLoggedIn, appConfig }) => {
  const navigate = useNavigate();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  return (
    <Box sx={{ display: { xs: "flex", md: "none" } }}>
      <Button
        sx={{ p: 0, minWidth: "unset" }}
        size='small'
        aria-label='show more'
        aria-controls='mobile-menu'
        aria-haspopup='true'
        onClick={handleMobileMenuOpen}
        color='inherit'
      >
        <MoreIcon color='primary' />
      </Button>
      <Menu
        id='mobile-menu'
        anchorEl={mobileMoreAnchorEl}
        keepMounted
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem
          onClick={() => {
            navigate(isLoggedIn ? "/dashboard/home" : "/");
            handleMobileMenuClose();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img src={home} alt='Home' style={{ width: 20, height: 20 }} />
          <Typography fontSize={12}>Home</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/about");
            handleMobileMenuClose();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img src={about} alt='About' style={{ width: 20, height: 20 }} />
          <Typography fontSize={12}>About</Typography>
        </MenuItem>
        {appConfig && appConfig.APP_ENVIRONMENT !== "production" && (
          <>
            <MenuItem
              onClick={() => {
                navigate("");
                handleMobileMenuClose();
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <img
                src={messaging}
                alt='Messaging'
                style={{ width: 20, height: 20 }}
              />
              <Typography fontSize={12}>Messaging</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("");
                handleMobileMenuClose();
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <img
                src={notification}
                alt='Notifications'
                style={{ width: 20, height: 20 }}
              />
              <Typography fontSize={12}>Notifications</Typography>
            </MenuItem>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default MobileMenu;
