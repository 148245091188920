import React, { ElementType, FC, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TSAppBar from "@components/AppBar/TSAppBar";
import UserBlock from "@components/UserBlock/UserBlock";
import { getUser } from "@features/Auth/authSlice";
import {
  getInstitutionById,
  getLabInfo,
  selectInstitutionById
} from "@features/Lab/labslice";
import {
  socketJoinRoom,
  socketLeaveRoom
} from "@features/sockets/socket-events";
import useRequireSession from "@hooks/useRequireSession";
import { Add, LockOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import donate from "@static/icons/donate-icon.svg";
import home from "@static/icons/home.svg";
import labSlab from "@static/icons/labicon.svg";
import university from "@static/icons/portal-uni-Icon.svg";
import magnifier from "@static/icons/sidebar-magni-Icon.svg";
import people from "@static/icons/user-group.svg";
import userIcon from "@static/icons/user-icon.svg";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";

import DashboardBody from "./DashboardBody";
import DashboardNavigation from "./DashboardNavigation";
import DashboardProvider from "./DashboardProvider";
import DashboardSidebar from "./DashboardSidebar";

interface InstitutionNavItemProps {
  href: string;
  icon: string;
  children: ReactNode;
  isPartner: boolean;
}

const InstitutionNavItem: FC<
  InstitutionNavItemProps & { onLockedClick?: () => void }
> = ({ href, icon, children, isPartner, onLockedClick }) => (
  <Box
    onClick={() => {
      if (!isPartner && onLockedClick) {
        onLockedClick();
      }
    }}
    sx={{
      position: "relative",
      width: "100%",
      opacity: isPartner ? 1 : 0.7,
      "&:hover": {
        cursor: isPartner ? "pointer" : "not-allowed"
      }
    }}
  >
    <DashboardNavigation item href={isPartner ? href : "#"} icon={icon}>
      {children}
      {!isPartner && (
        <LockOutlined
          sx={{
            position: "absolute",
            right: 8,
            top: "50%",
            transform: "translateY(-50%)",
            fontSize: 16,
            color: "text.secondary"
          }}
        />
      )}
    </DashboardNavigation>
  </Box>
);

interface DashboardRouteProps {
  redirectUnauthorized: boolean;
  component: ElementType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const DashboardRoute: FC<DashboardRouteProps> = ({
  component: Component,
  redirectUnauthorized = false,
  ...rest
}) => {
  const [userId] = useRequireSession({
    redirect: redirectUnauthorized
  });

  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const institution = useSelector(selectInstitutionById);
  const isPartner = institution.is_partner;
  const labId: string = currentUser.labId;
  const isLoggedIn = Boolean(currentUser.id !== undefined);
  const [restrictAccess, setRestrictAccess] = useState(false);

  const handleLockedClick = () => {
    setRestrictAccess(true);
  };
  const handleUnlockedClick = () => {
    setRestrictAccess(false);
  };

  useEffect(() => {
    dispatch(getInstitutionById({ institutionId: currentUser.institutionId }));
  }, [dispatch, currentUser.institutionId]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getLabInfo(labId));
      dispatch(socketJoinRoom(currentUser.id));
    }
    return () => {
      dispatch(socketLeaveRoom(currentUser.id));
    };
  }, [dispatch, labId, isLoggedIn]);

  return (
    <DashboardProvider>
      {userId ? (
        <DashboardSidebar>
          <Divider sx={{ marginBottom: 4 }} />
          <DashboardNavigation>
            <Stack px={3} spacing={0.5}>
              <Typography
                color={APP_COLORS.subtitleColor}
                fontWeight={600}
                px={1}
              >
                Home
              </Typography>
              <DashboardNavigation
                item
                href={`/dashboard/home`}
                icon={home}
                onClick={handleUnlockedClick}
              >
                Public Feed
              </DashboardNavigation>
              <DashboardNavigation
                item
                href={`/dashboard/member/${userId}`}
                icon={userIcon}
                onClick={handleUnlockedClick}
              >
                My Profile
              </DashboardNavigation>
              <DashboardNavigation item href={"/dashboard/create"} button>
                <Button
                  variant='outlined'
                  size='small'
                  sx={{
                    borderRadius: 3,
                    px: 1
                  }}
                >
                  <Stack direction={"row"} alignItems={"center"}>
                    <Add sx={{ width: "17px" }} className='mr-1' />
                    <Typography pr={1}>Create a post</Typography>
                  </Stack>
                </Button>
              </DashboardNavigation>
            </Stack>
            <Divider />
            <Stack px={3} spacing={0.5}>
              <Typography
                color={APP_COLORS.subtitleColor}
                fontWeight={600}
                px={1}
              >
                My Institution
              </Typography>
              <InstitutionNavItem
                href='/dashboard'
                icon={university}
                isPartner={isPartner === true}
                onLockedClick={handleLockedClick}
              >
                Recent Activity
              </InstitutionNavItem>
              <InstitutionNavItem
                href='/dashboard/institution-database'
                icon={magnifier}
                isPartner={isPartner === true}
                onLockedClick={handleLockedClick}
              >
                Institutional Database
              </InstitutionNavItem>
              <InstitutionNavItem
                href={`/dashboard/lab?lab_id=${labId}`}
                icon={labSlab}
                isPartner={isPartner === true}
                onLockedClick={handleLockedClick}
              >
                My Lab&apos;s Profile
              </InstitutionNavItem>
              <InstitutionNavItem
                href='/dashboard/institution-search'
                icon={people}
                isPartner={isPartner === true}
                onLockedClick={handleLockedClick}
              >
                Lab & People Search
              </InstitutionNavItem>
            </Stack>
            <Divider />
            <Stack px={3} spacing={0.5}>
              <Typography
                color={APP_COLORS.subtitleColor}
                fontWeight={600}
                px={1}
              >
                Animal Facility
              </Typography>
              <InstitutionNavItem
                href='/dashboard/donate'
                icon={donate}
                isPartner={isPartner === true}
                onLockedClick={handleLockedClick}
              >
                Donate Mice For Training
              </InstitutionNavItem>
            </Stack>
          </DashboardNavigation>
          <Divider sx={{ my: 3 }} />
          <UserBlock currentUser={currentUser} />
        </DashboardSidebar>
      ) : null}
      <DashboardBody restrictAccess={restrictAccess}>
        <TSAppBar inDashboard />
        <Component {...rest} />
      </DashboardBody>
    </DashboardProvider>
  );
};

export default DashboardRoute;
