import React from "react";

import { CreateResourceFormValues } from "@interfaces/create-resource-form";
import { FormikResourceValues } from "@interfaces/resource-values";
import { Box, Typography } from "@mui/material";
import { FormikProps } from "formik";

export function displayErrorMessages(
  formik: FormikProps<CreateResourceFormValues | FormikResourceValues>
) {
  // Create an array to hold the names of missing required fields
  const missingFields = [];

  // Check for each required field and add the field name to the array if it's missing
  if (formik.errors.strainName) {
    missingFields.push("Strain name");
  }

  if (formik.errors.species_name) {
    missingFields.push("Species name");
  }

  if (formik.errors.mutation_type_names) {
    missingFields.push("Mutation type names");
  }

  if (formik.errors.target_gene_names) {
    missingFields.push("Target gene names");
  }

  // If there are missing fields, show the error message
  return (
    <Box textAlign={"center"}>
      {missingFields.length > 0 && (
        <Typography
          component='small'
          lineHeight={1.66}
          fontSize={"0.75rem"}
          color='error'
        >
          {`${missingFields.join(", ")} ${missingFields.length > 1 ? "are" : "is"} missing but required. Kindly search the database with a JAX or MGI ID to get them or use manual entry.`}
        </Typography>
      )}
    </Box>
  );
}
