import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  clearSearchResults,
  fetchMatchingExternalIds
} from "@features/InsitutionDatabase/resourceSlice";
import { debounce } from "lodash";
import { AppDispatch } from "src/services/store";

const useDebouncedLiveSearch = (delay = 300) => {
  const dispatch: AppDispatch = useDispatch();

  const debouncedSearch = useCallback(
    debounce((searchTerm, database) => {
      dispatch(clearSearchResults());
      if (searchTerm) {
        dispatch(fetchMatchingExternalIds({ database, searchTerm }));
      }
    }, delay),
    [dispatch, delay]
  );

  return debouncedSearch;
};

export default useDebouncedLiveSearch;
