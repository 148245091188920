declare global {
  interface Window {
    __APP_CONFIG__: {
      REACT_APP_SOCKET_URL: string;
      S3_BUCKET_NAME: string;
    };
  }
}

export const getConfig = () => {
  if (!window.__APP_CONFIG__) {
    throw new Error("Config not loaded");
  }
  return window.__APP_CONFIG__;
};

export const REACT_APP_SOCKET_URL = getConfig().REACT_APP_SOCKET_URL;
export const S3_BUCKET_NAME = getConfig().S3_BUCKET_NAME;
