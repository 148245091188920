import React, { FC } from "react";

import clsx from "@features/utils/clsx";
import FormatRelativeTime from "@features/utils/formatTime";
import { renderStrainName } from "@features/utils/render-strain-name";
import { Button } from "@mui/material";

interface DashboardNotificationProps {
  avatar: string;
  name: string;
  content: string;
  link: string;
  timestamp: string;
  className?: string;
  unread: boolean;
  notificationType: string;
  onMarkAsRead: () => void;
}

const DashboardNotification: FC<DashboardNotificationProps> = ({
  avatar,
  name,
  content,
  link,
  timestamp,
  className,
  unread,
  notificationType,
  onMarkAsRead
}) => {
  const truncatedContent =
    content.length > 50
      ? renderStrainName(content.slice(0, 50) + "...")
      : renderStrainName(content);

  const getNotificationTypeText = React.useCallback(
    (type: string, isButton = false) => {
      switch (type.toLowerCase()) {
        case "system_alert":
          return "alert";
        case "new_post":
          return "post";
        case "new_message":
          return isButton ? "entire message" : "message";
        case "new_search_result":
          return "search result";
        default:
          return "notification";
      }
    },
    [notificationType]
  );

  return (
    <div
      className={clsx(
        "flex p-4 relative rounded-md",
        unread ? "bg-teal-50" : "bg-white",
        className
      )}
      onClick={() => {
        onMarkAsRead();
        window.location.href = link;
      }}
    >
      {unread && (
        <span className='absolute right-5 top-1/2 h-3 w-3 rounded-full bg-teal-600'></span>
      )}
      <p className='absolute right-4 top-1 text-xs text-gray-500'>
        {FormatRelativeTime(timestamp)}
      </p>

      <img className='h-10 w-10 rounded-full' src={avatar} alt='user' />

      <div className='ml-3 text-left'>
        <p className='mb-1 leading-tight pr-3'>
          New {getNotificationTypeText(notificationType)} from{" "}
          <strong>{name}</strong>: {truncatedContent}
        </p>
        <Button
          sx={{ mt: 1, borderRadius: 3 }}
          variant='outlined'
          href={link}
          size='small'
          onClick={(e) => {
            e.preventDefault();
            onMarkAsRead();
            window.location.href = link;
          }}
        >
          View {getNotificationTypeText(notificationType, true)}
        </Button>
      </div>
    </div>
  );
};

export default DashboardNotification;
