import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchResources,
  resetResources,
  selectCurrentPage,
  selectResources,
  selectSortOption,
  selectTotalPages,
  setPage
} from "@features/InsitutionDatabase/resourceSlice";
import { constants } from "@features/utils/constants";
import useInfiniteScroll from "@hooks/useInfiniteScroll";
import { RenderSource } from "@interfaces/post";
import { ResourceValues } from "@interfaces/resource-values";
import { Stack } from "@mui/material";
import { AppDispatch } from "src/services/store";

import Entry from "./Entry";

interface EntryListProps {
  specificPosts?: ResourceValues[];
  instId?: string;
  scrollContainer?: string;
  renderSource: RenderSource;
  searchTerm?: string;
}

const EntryList: React.FC<EntryListProps> = ({
  specificPosts,
  instId,
  scrollContainer = "",
  renderSource,
  searchTerm
}) => {
  const dispatch: AppDispatch = useDispatch();
  const posts = useSelector(selectResources);
  const sortOption = useSelector(selectSortOption);
  const currentPage = useSelector(selectCurrentPage);
  const totalPages = useSelector(selectTotalPages);

  const postsSorted = useMemo(() => {
    if (!posts) return [];
    const postsToFilter = specificPosts ? specificPosts : posts;
    const institutionPosts = postsToFilter.filter(
      (post) =>
        post.contact_user_details?.institution_name &&
        post.contact_user_details.institution_name.trim() !== ""
    );
    return institutionPosts.sort((a, b) => {
      const dateA = new Date(a.created_at as string).getTime();
      const dateB = new Date(b.created_at as string).getTime();

      if (sortOption === "recent") {
        return dateB - dateA;
      } else if (sortOption === "oldest") {
        return dateA - dateB;
      }
      return 0;
    });
  }, [specificPosts, posts, sortOption]);

  const loadMore = useCallback(() => {
    if (renderSource === constants.profile) {
      return null;
    }
    if (currentPage < totalPages) {
      dispatch(setPage(currentPage + 1));
      dispatch(
        fetchResources({
          params: {
            ...(searchTerm ? { search: searchTerm } : {}),
            page: currentPage + 1,
            limit: 20,
            sources:
              renderSource === constants.home
                ? constants.homeResource
                : constants.institutionalDBSources,
            ...(instId
              ? { institution_id: instId, public: false }
              : { public: true })
          },
          append: true
        })
      );
    }
  }, [renderSource, dispatch, currentPage, totalPages, instId, searchTerm]);

  useInfiniteScroll({
    scrollContainer: scrollContainer
      ? document.querySelector(scrollContainer)
      : null,
    shouldScroll: () => currentPage < totalPages,
    onLoad: loadMore,
    offset: 200,
    debounceTime: 300
  });

  useEffect(() => {
    if (!specificPosts) {
      dispatch(resetResources());
      dispatch(
        fetchResources({
          params: {
            ...(searchTerm ? { search: searchTerm } : {}),
            page: 1,
            limit: 20,
            sources: constants.homeResource,
            ...(instId ? { institution_id: instId } : { public: true })
          }
        })
      );
    }
  }, [instId, dispatch, specificPosts, searchTerm]);

  return (
    <Stack spacing={4}>
      {postsSorted.map((post) => (
        <Entry key={post.id as string} post={post} />
      ))}
    </Stack>
  );
};

export default EntryList;
