import React from "react";

import { ResourceAutocomplete } from "@components/AutoComplete/ResourceAutocomplete";
import { optionsFilter } from "@features/utils/automplete-filter";
import { CreateResourceFormValues } from "@interfaces/create-resource-form";
import {
  FormikResourceValues,
  ResourceProperties
} from "@interfaces/resource-values";
import { Stack } from "@mui/material";
import { FormikProps } from "formik";

interface ManualEntryCellLineFieldsProps {
  isMobile: boolean;
  disabledFields: Record<string, boolean>;
  setDisabledFields: React.Dispatch<
    React.SetStateAction<{
      cellLineName: boolean;
      cellLineSpeciesName: boolean;
      tissue_name: boolean;
      disease_name: boolean;
    }>
  >;
  formik: FormikProps<CreateResourceFormValues | FormikResourceValues>;
  resourcePropsNames: ResourceProperties;
  loading: boolean;
  setResourcePropsValues: React.Dispatch<
    React.SetStateAction<ResourceProperties>
  >;
  resourcePropsValues: Record<string, string[]>;
  handleClearConnectedFields: () => void;
}

const ManualEntryCellLineFields: React.FC<ManualEntryCellLineFieldsProps> = ({
  formik,
  resourcePropsNames,
  loading,
  setResourcePropsValues,
  resourcePropsValues,
  isMobile
}) => {
  return (
    <Stack spacing={3}>
      <ResourceAutocomplete
        id='cellLineName'
        value={formik.values.cellLineName || ""}
        resourceNames={resourcePropsNames?.cell_line || []}
        label='Name of Cell Line'
        loading={loading}
        requiredField
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            name: newValue
          });
          formik.setFieldValue("cellLineName", newValue?.toString() || "");
        }}
        error={
          formik.touched.cellLineName && Boolean(formik.errors.cellLineName)
        }
        helperText={
          (formik.touched.cellLineName && formik.errors.cellLineName) || ""
        }
      />
      <Stack direction={isMobile ? "column" : "row"} spacing={3}>
        <ResourceAutocomplete
          id='cellLineSpeciesName'
          resourceNames={resourcePropsNames?.species_name || []}
          label='Species'
          value={formik.values.cellLineSpeciesName || ""}
          requiredField
          loading={loading}
          optionsFilter={optionsFilter}
          onChange={(_, newValue) => {
            setResourcePropsValues({
              ...resourcePropsValues,
              cellLineSpeciesName: newValue
            });
            formik.setFieldValue(
              "cellLineSpeciesName",
              newValue?.toString() || ""
            );
          }}
          error={
            formik.touched.cellLineSpeciesName &&
            Boolean(formik.errors.cellLineSpeciesName)
          }
          helperText={
            (formik.touched.cellLineSpeciesName &&
              formik.errors.cellLineSpeciesName) ||
            ""
          }
        />
        <ResourceAutocomplete
          id='tissue_name'
          value={formik.values.tissue_name || ""}
          resourceNames={resourcePropsNames?.tissue_name || []}
          label='Tissue Name'
          loading={loading}
          requiredField
          optionsFilter={optionsFilter}
          onChange={(_, newValue) => {
            setResourcePropsValues({
              ...resourcePropsValues,
              tissue_name: newValue
            });
            formik.setFieldValue("tissue_name", newValue?.toString() || "");
          }}
          error={
            formik.touched.tissue_name && Boolean(formik.errors.tissue_name)
          }
          helperText={
            (formik.touched.tissue_name && formik.errors.tissue_name) || ""
          }
        />
      </Stack>
      <ResourceAutocomplete
        id={"disease_name"}
        value={formik.values.disease_name || ""}
        resourceNames={resourcePropsNames?.disease_name || []}
        label={"Disease Name"}
        loading={loading}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            disease_name: newValue
          });
          formik.setFieldValue("disease_name", newValue?.toString() || "");
        }}
        error={
          formik.touched.disease_name && Boolean(formik.errors.disease_name)
        }
        helperText={
          (formik.touched.disease_name && formik.errors.disease_name) || ""
        }
      />
      <ResourceAutocomplete
        id={"morphology"}
        value={formik.values.morphology || ""}
        resourceNames={resourcePropsNames?.morphology || []}
        label={"Morphology"}
        loading={loading}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            morphology: newValue
          });
          formik.setFieldValue("morphology", newValue?.toString() || "");
        }}
        error={formik.touched.morphology && Boolean(formik.errors.morphology)}
        helperText={
          (formik.touched.morphology && formik.errors.morphology) || ""
        }
      />
    </Stack>
  );
};

export default ManualEntryCellLineFields;
