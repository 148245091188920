import { constants } from "@features/utils/constants";

import Post from "./post";
import { ResourceUser } from "./user";

export interface ResourceValues extends Post {
  jax_external_id?: string;
  mgi_external_id?: string;
  add_gene_external_id?: string;
  name?: string;
  synonyms?: string;
  isotype?: string;
  target_gene_names?: string[];
  cellLineName?: string;
  atcc_external_id?: string;
  tissue_name?: string;
  disease_name?: string;
  morphology?: string;
  mutation_names?: string[] | string;
  mutation_type_names?: string[];
  storage_condition_names?: string[] | string;
  detailsComments?: string;
  contact_user_details?: ResourceUser;
  lab_id?: string;
  lab_name?: string;
  institution_id?: string;
  strainName?: string;
  species_name?: string;
  manufacturer?: string;
  model?: string;
  category?: string;
  source?: "user_post_share" | "user_post_request";
  public?: boolean;
}

export interface NewResource {
  category: string;
  type: string;
  tags: string[];
  institution_id: string;
  species: string;
  description: string;
  title: string;
  jax_external_id: string;
  mgi_external_id: string;
  public: boolean;
}

export interface FormikResourceValues {
  id?: string;
  category?: string;
  jax_external_id?: string;
  mgi_external_id?: string;
  add_gene_external_id?: string;
  name?: string;
  synonyms?: string;
  isotype?: string;
  synonyms_list?: string[];
  mutation_type_names?: string[] | string;
  target_gene_names?: string[] | string;
  cellLineName?: string;
  atcc_external_id?: string;
  tissue_name?: string;
  disease_name?: string;
  mutation_names?: string[] | string;
  storage_condition_names?: string[] | string;
  title?: string;
  description?: string;
  contact_user_id?: string;
  contact_user_email?: string;
  lab_id?: string;
  lab_name?: string;
  institution_id?: string;
  strainName?: string;
  species_name?: string;
  cellLineSpeciesName?: string;
  source?: string;
  databaseSelection?: string;
  databaseSelect?: string;
  cellLineDBSelect?: string;
  manufacturer?: string;
  tag_names?: string[] | string;
  model?: string;
  morphology?: string;
  public?: boolean;
  // "user_post_share" | "user_post_request" | "lab_member_upload";
}

export interface ResourceProperty {
  name: string;
}

export interface ResourceProperties {
  [key: string]: string[];
}

export const initialCreateValues = {
  //required animal
  strainName: "",
  category: constants.animalModel,
  contact_user_id: "",
  source: "",
  lab_id: "",
  species_name: "",
  //required cell
  cellLineName: "",
  tissue_name: "",
  cellLineSpeciesName: "",
  databaseSelect: "jax",
  cellLineDBSelect: "atcc",
  //optional
  name: "",
  atcc_external_id: "",
  add_gene_external_id: "",
  mutation_names: "",
  storage_condition_names: "",
  disease_name: "",
  jax_external_id: "",
  mgi_external_id: "",
  tag_names: "",
  mutation_type_names: [] as string[],
  target_gene_names: [] as string[],
  synonyms: "",
  isotype: "",
  title: "",
  description: "",
  model: "",
  morphology: ""
};

export const initialAddDialogValues = {
  category: constants.animalModel,
  jax_external_id: "",
  mgi_external_id: "",
  add_gene_external_id: "",
  name: "",
  strainName: "",
  synonyms: "",
  isotype: "",
  mutation_type_names: [] as string[],
  target_gene_names: [] as string[],
  cellLineName: "",
  species_name: "",
  cellLineSpeciesName: "",
  databaseSelection: "jax",
  databaseSelect: "jax",
  cellLineDBSelect: "atcc",
  atcc_external_id: "",
  tissue_name: "",
  disease_name: "",
  mutation_names: "",
  storage_condition_names: "",
  description: "",
  manufacturer: "",
  model: "",
  tag_names: "",
  title: "",
  source: constants.labUpload,
  id: "",
  morphology: "",
  public: false
};
