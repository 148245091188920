import React from "react";

import { ResourceAutocomplete } from "@components/AutoComplete/ResourceAutocomplete";
import { optionsFilter } from "@features/utils/automplete-filter";
import { CreateResourceFormValues } from "@interfaces/create-resource-form";
import {
  FormikResourceValues,
  ResourceProperties
} from "@interfaces/resource-values";
import { Stack } from "@mui/material";
import { FormikProps } from "formik";

interface EquipmentFieldsProps {
  formik: FormikProps<CreateResourceFormValues | FormikResourceValues>;
  resourcePropsNames: ResourceProperties;
  loading: boolean;
  setResourcePropsValues: React.Dispatch<
    React.SetStateAction<ResourceProperties>
  >;
  resourcePropsValues: Record<string, string[]>;
}

const EquipmentFields: React.FC<EquipmentFieldsProps> = ({
  loading,
  resourcePropsNames,
  setResourcePropsValues,
  resourcePropsValues,
  formik
}) => {
  return (
    <Stack spacing={3}>
      <ResourceAutocomplete
        id={"name"}
        resourceNames={resourcePropsNames?.equipment || []}
        label={"Name of Equipment"}
        loading={loading}
        requiredField
        value={formik.values.name || ""}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            name: newValue
          });
          formik.setFieldValue("name", newValue?.toString() || "");
        }}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={(formik.touched.name && formik.errors.name) || ""}
      />
      <ResourceAutocomplete
        id={"manufacturer"}
        resourceNames={resourcePropsNames?.manufacturer || []}
        label={"Manufacturer"}
        loading={loading}
        value={formik.values.manufacturer || ""}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            model: newValue
          });
          formik.setFieldValue("manufacturer", newValue?.toString() || "");
        }}
        error={
          formik.touched.manufacturer && Boolean(formik.errors.manufacturer)
        }
        helperText={
          (formik.touched.manufacturer && formik.errors.manufacturer) || ""
        }
      />
      <ResourceAutocomplete
        id={"model"}
        resourceNames={resourcePropsNames?.model || []}
        label={"Model"}
        value={formik.values.model || ""}
        loading={loading}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            model: newValue
          });
          formik.setFieldValue("model", newValue?.toString() || "");
        }}
        error={formik.touched.model && Boolean(formik.errors.model)}
        helperText={(formik.touched.model && formik.errors.model) || ""}
      />
    </Stack>
  );
};

export default EquipmentFields;
