import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logout } from "@features/Auth/authSlice";
import { selectUnreadCount } from "@features/Notifications/notificationSlice";
import useNotifications from "@hooks/useNotifications";
import { Box, Button, Typography } from "@mui/material";
import about from "@static/icons/about.svg";
import home from "@static/icons/home.svg";
import logoutSVG from "@static/icons/logout.svg";
import messaging from "@static/icons/messaging.svg";
import notification from "@static/icons/notification.svg";
import DashboardNotifications from "src/layout/dashboard/DashboardNotifications";
import { AppDispatch } from "src/services/store";

interface NavigationButtonsProps {
  isLoggedIn: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appConfig: any;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  isLoggedIn,
  appConfig
}) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [, notificationsActions] = useNotifications();
  const unreadNotificationCount = useSelector(selectUnreadCount);

  const handleLoginLogout = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (isLoggedIn) {
      const logoutResponse = await dispatch(logout());
      if (logoutResponse.payload.logoutUrl) {
        return (window.location.href = logoutResponse.payload.logoutUrl);
      }
      navigate("/");
    } else {
      navigate("/get-started");
    }
  };

  const handleMessagingButtonClick = () => {
    navigate("/dashboard/messaging");
  };

  return (
    <Box
      sx={{
        width: "auto",
        display: { xs: "none", md: "flex" },
        justifyContent: "space-between",
        alignItems: "center",
        gap: 3
      }}
    >
      <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
        <Button
          size='small'
          color='inherit'
          href={isLoggedIn ? "/dashboard/home" : "/"}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <img src={home} alt='Home' style={{ width: 20, height: 20 }} />
          <Typography fontSize={12}>Home</Typography>
        </Button>
        <Button
          size='small'
          color='inherit'
          href='/about'
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <img src={about} alt='About' style={{ width: 20, height: 20 }} />
          <Typography fontSize={12}>About</Typography>
        </Button>
        <Button
          size='small'
          color='inherit'
          href=''
          sx={{
            display: "flex",
            flexDirection: "column"
          }}
          onClick={handleMessagingButtonClick}
        >
          <img
            src={messaging}
            alt='Messaging'
            style={{ width: 20, height: 20 }}
          />
          <Typography fontSize={12}>Messaging</Typography>
        </Button>
        <Button
          size='small'
          color='inherit'
          href=''
          onClick={
            (
              notificationsActions as {
                on: () => void;
                off: () => void;
                toggle: () => void;
              }
            ).toggle
          }
          sx={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <img
            src={notification}
            alt='Notifications'
            style={{ width: 20, height: 20 }}
          />
          <Typography fontSize={12}>Notifications</Typography>
          <DashboardNotifications />
          {unreadNotificationCount > 0 && (
            <span className='absolute top-1 right-7 -mt-1 -mr-2 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-white text-xs'>
              {unreadNotificationCount}
            </span>
          )}
        </Button>
      </Box>
      <Box
        sx={{
          gap: "10px",
          display: { xs: "none", md: "flex" },
          alignItems: "center"
        }}
      >
        <Typography fontSize={12}>
          <Button
            size='small'
            aria-label={isLoggedIn ? "log out" : "log in"}
            onClick={handleLoginLogout}
            variant={!isLoggedIn ? "outlined" : "contained"}
            sx={{ width: "max-content" }}
          >
            {isLoggedIn ? (
              <>
                <span>Logout</span>
                <img
                  src={logoutSVG}
                  alt='logout'
                  style={{ width: 14, height: 14, marginLeft: 3 }}
                />
              </>
            ) : (
              <span>Login</span>
            )}
          </Button>
        </Typography>
        {!isLoggedIn && (
          <Typography fontSize={12}>
            <Button
              size='small'
              aria-label='register button'
              href='/get-started'
              variant='contained'
              sx={{ width: "max-content" }}
            >
              Sign Up
            </Button>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default NavigationButtons;
