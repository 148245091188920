import React from "react";

import { ResourceAutocomplete } from "@components/AutoComplete/ResourceAutocomplete";
import { optionsFilter } from "@features/utils/automplete-filter";
import { CreateResourceFormValues } from "@interfaces/create-resource-form";
import {
  FormikResourceValues,
  ResourceProperties
} from "@interfaces/resource-values";
import { Stack } from "@mui/material";
import { FormikProps } from "formik";

interface OtherFieldsProps {
  formik: FormikProps<CreateResourceFormValues | FormikResourceValues>;
  resourcePropsNames: ResourceProperties;
  loading: boolean;
  setResourcePropsValues: React.Dispatch<
    React.SetStateAction<ResourceProperties>
  >;
  resourcePropsValues: Record<string, string[]>;
}

const OtherFields: React.FC<OtherFieldsProps> = ({
  loading,
  resourcePropsNames,
  setResourcePropsValues,
  resourcePropsValues,
  formik
}) => {
  return (
    <Stack spacing={3}>
      <ResourceAutocomplete
        id={"name"}
        resourceNames={resourcePropsNames?.equipment || []}
        label={"Name of Resource"}
        value={formik.values.name || []}
        loading={loading}
        requiredField
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            name: newValue
          });
          formik.setFieldValue("name", newValue?.toString() || "");
        }}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={(formik.touched.name && formik.errors.name) || ""}
      />
    </Stack>
  );
};

export default OtherFields;
