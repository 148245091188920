import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchResources } from "@features/InsitutionDatabase/resourceSlice";
import { constants } from "@features/utils/constants";
import { enqueueSnackbar } from "notistack";
import { AppDispatch } from "src/services/store";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useHandleSearchSubmit = (setSearchTerm?: (term: any) => void) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearchSubmit = async (
    searchTerm: string,
    event?: React.FormEvent
  ) => {
    if (event) {
      event.preventDefault();
    }

    try {
      const resultAction = await dispatch(
        fetchResources({
          params: {
            search: searchTerm,
            page: 1,
            limit: 20,
            sources: constants.homeResource
          },
          append: true
        })
      ).unwrap();

      // If setSearchTerm is provided, clear the search term (in search bar)
      if (setSearchTerm) {
        setSearchTerm("");
      }

      // Send the user to search result page with posts in state
      navigate("/dashboard/search", {
        state: {
          specificPosts: resultAction.resources,
          searchTerms: searchTerm
        }
      });
    } catch (error) {
      enqueueSnackbar("Search failed. Please try again.", {
        variant: "error"
      });
    }
  };

  return handleSearchSubmit;
};
