import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SearchComponent } from "@components/Search";
import { getUser } from "@features/Auth/authSlice";
import {
  getInstituitionMembers,
  selectFetchInstLoading,
  selectInstituitionMembers,
  selectPage,
  selectQuery,
  selectTotalCount,
  selectTotalPages,
  setPage,
  setQuery
} from "@features/Lab/labslice";
import Pagination from "@features/Search/components/Pagination";
import { socketCreateRoom } from "@features/sockets/socket-events";
import { constants } from "@features/utils/constants";
import { useQuery } from "@hooks/useQuery";
import { LabMember } from "@interfaces/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import close from "@static/icons/close.svg";
import createMessage from "@static/icons/create-message.svg";
import { DashboardContent } from "src/layout/dashboard";
import { AppDispatch } from "src/services/store";
import { APP_COLORS } from "src/styles/colors";

import ChatView from "./components/ChatView";
import MessageList from "./components/MessageList";

export default function MessagingPage() {
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const [newChatUser, setNewChatUser] = useState<LabMember>();
  const [showUsersToMessage, setShowUsersToMessage] = useState<boolean | null>(
    false
  );

  const uniMembers: LabMember[] = useSelector(selectInstituitionMembers);

  const query = useQuery();
  const searchQuery = useSelector(selectQuery);
  const page = useSelector(selectPage);
  const totalPages = useSelector(selectTotalPages);
  const [prevPage, setPrevPage] = useState(page);
  const [prevQuery, setPrevQuery] = useState(searchQuery);
  const total = useSelector(selectTotalCount);
  const loading = useSelector(selectFetchInstLoading);

  const [showMessageList, setShowMessageList] = useState(true);
  const filteredMembers = uniMembers.filter((uniMember) =>
    `${uniMember.first_name} ${uniMember.last_name}`.toLowerCase()
  );

  const handleCreateRoom = async (participant: LabMember) => {
    setNewChatUser(participant);
    setShowUsersToMessage(false);
    dispatch(
      socketCreateRoom({
        room_type: constants.privateChat,
        creator_id: currentUser.id,
        participant_id: participant.user_id
      })
    );
  };

  useEffect(() => {
    dispatch(
      getInstituitionMembers({
        institutionId: currentUser.institutionId
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const queryText = query.get("query") || "";
    if (queryText !== searchQuery) {
      dispatch(setQuery(queryText));
    }
  }, [dispatch, query, searchQuery]);

  const getPeople = (searchText: string) => {
    dispatch(
      getInstituitionMembers({
        institutionId: currentUser.institutionId,
        page,
        search: searchText
      })
    );
  };

  useEffect(() => {
    if (prevPage !== page || prevQuery !== searchQuery) {
      dispatch(
        getInstituitionMembers({
          institutionId: currentUser.institutionId,
          page,
          search: searchQuery
        })
      );
      setPrevPage(page);
      setPrevQuery(searchQuery);
    }
  }, [
    dispatch,
    currentUser.institutionId,
    page,
    searchQuery,
    prevPage,
    prevQuery
  ]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage !== page) {
        dispatch(setPage(newPage));
      }
    },
    [dispatch, page]
  );
  return (
    <DashboardContent>
      <Box
        sx={{
          maxWidth: "900px",
          margin: "0 auto",
          backgroundColor: `${APP_COLORS.white}`,
          height: { xs: "calc(100vh - 120px)", sm: "calc(100vh - 160px)" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 2,
            border: "1px solid",
            borderColor: "grey.300",
            borderRadius: 2,
            padding: 2
          }}
        >
          <Typography
            variant='h6'
            sx={{
              fontSize: { xs: "1rem", sm: "1.25rem" }
            }}
          >
            Messaging
          </Typography>
          <IconButton
            onClick={() => {
              setShowUsersToMessage(true);
              setShowMessageList(false);
            }}
          >
            <img src={createMessage} alt='create-message' />{" "}
          </IconButton>
        </Box>

        <Grid
          container
          sx={{
            border: "1px solid",
            borderColor: "grey.300",
            borderRadius: 2,
            borderTop: "none",
            flexDirection: isMobile ? "column" : "row",
            height: "calc(100% - 80px)"
          }}
        >
          {(showMessageList || !isMobile) && (
            <Grid
              item
              xs={isMobile ? 12 : 4}
              sx={{
                borderRight: isMobile ? "none" : "1px solid",
                borderColor: "grey.300",
                overflowY: "auto",
                maxHeight: "calc(100vh - 220px)"
              }}
            >
              <MessageList setShowMessageList={setShowMessageList} />
            </Grid>
          )}

          {/* Chat View */}
          {!showMessageList || !isMobile ? (
            <Grid
              item
              xs={isMobile ? 12 : 8}
              sx={{
                paddingLeft: isMobile ? 0 : 2,
                height: "100%"
              }}
            >
              {isMobile && (
                <IconButton onClick={() => setShowMessageList(true)}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              {showUsersToMessage === true ? (
                <Box p={2} height='100%'>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderColor: "grey.300",
                      marginBottom: "20px"
                    }}
                  >
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: { xs: "1rem", sm: "1.25rem" }
                      }}
                    >
                      New message
                    </Typography>
                    <IconButton onClick={() => setShowUsersToMessage(false)}>
                      <img src={close} alt='create-message' />{" "}
                    </IconButton>
                  </Box>
                  <SearchComponent
                    setQuery={(search?: string) =>
                      dispatch(setQuery(search || ""))
                    }
                    placeholder='Search for people within your institution'
                    onFetch={getPeople}
                    isMessagingPage={true}
                  />
                  <List
                    sx={{
                      maxHeight: {
                        xs: "calc(100% - 160px)",
                        sm: "calc(100% - 200px)"
                      },
                      overflowY: "auto"
                    }}
                  >
                    {filteredMembers.length > 0 ? (
                      filteredMembers
                        .filter(
                          (uniMember) => uniMember.user_id !== currentUser.id
                        )
                        .slice(0, 10)
                        .map((uniMember) => (
                          <ListItem
                            button
                            key={uniMember.user_id}
                            onClick={() => handleCreateRoom(uniMember)}
                          >
                            <Avatar src={"none"} />
                            <ListItemText
                              primary={`${uniMember.first_name} ${uniMember.last_name}`}
                              secondary={uniMember.lab_name}
                              sx={{ marginLeft: 2 }}
                            />
                          </ListItem>
                        ))
                    ) : (
                      <ListItem>
                        <ListItemText primary='No results found' />
                      </ListItem>
                    )}
                    <ListItem>
                      {filteredMembers.length > 0 ? (
                        <Pagination
                          className='border border-t-0 rounded'
                          total={total}
                          totalPages={totalPages}
                          page={page}
                          limit={10}
                          setPage={handlePageChange}
                          loading={loading.uniMembers}
                        />
                      ) : null}
                    </ListItem>
                  </List>
                </Box>
              ) : null}
              {showUsersToMessage === false ? (
                <Box height='100%'>
                  <ChatView newChatUser={newChatUser} />
                </Box>
              ) : null}
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </DashboardContent>
  );
}
