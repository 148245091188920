export const isColumbiaEmail = (emailAddress: string): boolean => {
  const pattern = /\b[a-zA-Z0-9._+-]+@([a-zA-Z0-9.-]+)?columbia\.edu\b/;
  return pattern.test(emailAddress);
};

export const isSSOUser = (email: string | undefined): boolean => {
  if (!email) {
    return false;
  }
  if (isColumbiaEmail(email)) {
    return true;
  }

  return false;
};
