import React from "react";

import { Box, Link as MuiLink } from "@mui/material";
import AppLogo from "@static/tissuesharelogo_teal.png";
import MobileLogo from "@static/tissuesharelogo_teal_icon_only.png";

const Logo: React.FC = () => (
  <MuiLink
    href='/'
    underline='none'
    variant='h6'
    noWrap
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: { xs: 100, sm: "fit-content" },
      overflow: "hidden"
    }}
  >
    <Box
      component='img'
      sx={{
        width: "100%",
        objectFit: "contain",
        height: { xs: 30 },
        display: { xs: "none", md: "block" }
      }}
      alt='TissueShare logo'
      src={AppLogo}
    />
    <Box
      component='img'
      sx={{
        height: { xs: 30, lg: 40 },
        display: { xs: "block", md: "none" }
      }}
      alt='TissueShare logo'
      src={MobileLogo}
    />
  </MuiLink>
);

export default Logo;
