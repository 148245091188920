import {
  FormikResourceValues,
  ResourceProperties
} from "@interfaces/resource-values";

import { constants } from "./constants";

export const transformResourceData = (values: FormikResourceValues) => {
  console.log("DATA BEFORE TRANSFORMATION", values);

  const resourceData = {
    ...values,
    storage_condition_names: Array.isArray(values.storage_condition_names)
      ? values.storage_condition_names // If it's already an array, use it
      : values.storage_condition_names
        ? (values.storage_condition_names as string).split(",") // If it's a string, split by commas
        : [],
    mutation_names: values.mutation_names
      ? (values.mutation_names as string).split(",")
      : [],
    target_gene_names: values.target_gene_names ? values.target_gene_names : [],
    mutation_type_names: values.mutation_type_names
      ? values.mutation_type_names
      : [],
    synonyms_list: values.synonyms
      ? (values.synonyms as string).split(",")
      : [],
    ...(values.cellLineName
      ? { name: values.cellLineName }
      : { name: values.strainName }),
    ...(values.name ? { name: values.name } : null),
    ...(values.cellLineSpeciesName
      ? { species_name: values.cellLineSpeciesName }
      : { species_name: values.species_name }),
    public: values.public
  };

  delete resourceData.cellLineName;
  delete resourceData.strainName;
  delete resourceData.synonyms;
  delete resourceData.cellLineSpeciesName;

  // Remove unwanted fields based on category
  if (resourceData.category === constants.animalModel) {
    delete resourceData.cellLineName;
    delete resourceData.cellLineSpeciesName;
    delete resourceData.atcc_external_id;
    delete resourceData.tissue_name;
    delete resourceData.disease_name;
    delete (resourceData as FormikResourceValues).storage_condition_names;
    delete (resourceData as FormikResourceValues).mutation_names;
  } else {
    delete resourceData.jax_external_id;
    delete resourceData.mgi_external_id;
    delete resourceData.strainName;
    delete (resourceData as FormikResourceValues).target_gene_names;
    delete (resourceData as FormikResourceValues).mutation_type_names;
  }

  console.log("DATA AFTER TRANSFORMATION", values);

  return resourceData;
};

type Item = { name: string };

//eslint-disable-next-line
export function transformArray(arr: any): ResourceProperties {
  return Object.entries(arr).reduce((acc, [key, value]) => {
    acc[key] = Array.from(new Set((value as Item[]).map((item) => item.name)));
    return acc;
  }, {} as ResourceProperties);
}
