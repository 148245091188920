import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  fetchUnreadNotifications,
  markNotificationAsRead,
  selectUnreadNotifications
} from "@features/Notifications/notificationSlice";
import { BASE_S3_URL } from "@features/utils/manage-file";
import useNotifications from "@hooks/useNotifications";
import { Typography } from "@mui/material";
import DefaultProfile from "@static/icons/default-profile.png";
import { AppDispatch } from "src/services/store";

import DashboardNotification from "./DashboardNotification";
import DashboardPopout from "./DashboardPopout";

const DashboardNotifications = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [notificationsActive, notificationsActions] = useNotifications();
  const unreadNotifications = useSelector(selectUnreadNotifications);

  useEffect(() => {
    if (notificationsActive) {
      dispatch(fetchUnreadNotifications());
    }
  }, [dispatch, notificationsActive]);

  const handleMarkAsRead = (notificationId: string) => {
    dispatch(markNotificationAsRead(notificationId));
  };

  const handleNavigate = () => {
    return navigate("/dashboard/notifications", { replace: true });
  };

  return (
    <DashboardPopout
      active={notificationsActive as boolean}
      onRequestClose={
        (
          notificationsActions as {
            on: () => void;
            off: () => void;
            toggle: () => void;
          }
        ).off
      }
      className='origin-top-right right-0 top-10'
      classNameContent='divide-y divide-gray-200'
    >
      {unreadNotifications.length > 0 ? (
        <div className='bg-cool-gray-100 h-64 overflow-y-auto divide-y divide-gray-200'>
          {unreadNotifications.map((notification) => (
            <DashboardNotification
              key={notification.id}
              avatar={
                notification.avatar
                  ? `${BASE_S3_URL}${notification.avatar}`
                  : DefaultProfile
              }
              name={notification.name}
              content={notification.message}
              link={notification.link}
              timestamp={notification.created_at}
              notificationType={notification.notification_type}
              unread={!notification.is_read}
              onMarkAsRead={() => handleMarkAsRead(notification.id as string)}
            />
          ))}
        </div>
      ) : (
        <div className='bg-cool-gray-100 h-10 pt-2'>
          <Typography>You have no new notifications</Typography>
        </div>
      )}
      <button
        onClick={handleNavigate}
        className='flex w-full justify-center p-4 focus:outline-none hover:bg-teal-50 duration-150 transition ease-in-out'
      >
        <Link
          to='/dashboard/notifications'
          className='text-teal-500 text-sm font-medium'
        >
          View All
        </Link>
      </button>
    </DashboardPopout>
  );
};

export default DashboardNotifications;
