import React from "react";

import { ResourceAutocomplete } from "@components/AutoComplete/ResourceAutocomplete";
import { optionsFilter } from "@features/utils/automplete-filter";
import { CreateResourceFormValues } from "@interfaces/create-resource-form";
import {
  FormikResourceValues,
  ResourceProperties
} from "@interfaces/resource-values";
import { Stack } from "@mui/material";
import { FormikProps } from "formik";

interface BiospecimenFieldsProps {
  formik: FormikProps<CreateResourceFormValues | FormikResourceValues>;
  resourcePropsNames: ResourceProperties;
  loading: boolean;
  setResourcePropsValues: React.Dispatch<
    React.SetStateAction<ResourceProperties>
  >;
  resourcePropsValues: Record<string, string[]>;
}

const BiospecimenFields: React.FC<BiospecimenFieldsProps> = ({
  loading,
  resourcePropsNames,
  setResourcePropsValues,
  resourcePropsValues,
  formik
}) => {
  return (
    <Stack spacing={3}>
      <ResourceAutocomplete
        id={"name"}
        resourceNames={resourcePropsNames?.biospecimen || []}
        label={"Name of Biospecimen"}
        value={formik.values.name || ""}
        loading={loading}
        requiredField
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            name: newValue
          });
          formik.setFieldValue("name", newValue?.toString() || "");
        }}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={(formik.touched.name && formik.errors.name) || ""}
      />
      <ResourceAutocomplete
        id={"species_name"}
        resourceNames={resourcePropsNames?.species_name || []}
        label={"Species"}
        value={formik.values.species_name || ""}
        requiredField
        loading={loading}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            cellLineSpeciesName: newValue
          });
          formik.setFieldValue("species_name", newValue?.toString() || "");
        }}
        error={
          formik.touched.species_name && Boolean(formik.errors.species_name)
        }
        helperText={
          (formik.touched.species_name && formik.errors.species_name) || ""
        }
      />
      <ResourceAutocomplete
        id={"tissue_name"}
        resourceNames={resourcePropsNames?.tissue_name || []}
        label={"Tissue Name"}
        value={formik.values.tissue_name || ""}
        loading={loading}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            tissue_name: newValue
          });
          formik.setFieldValue("tissue_name", newValue?.toString() || "");
        }}
        error={formik.touched.tissue_name && Boolean(formik.errors.tissue_name)}
        helperText={
          (formik.touched.tissue_name && formik.errors.tissue_name) || ""
        }
      />
      <ResourceAutocomplete
        id={"disease_name"}
        resourceNames={resourcePropsNames?.disease_name || []}
        label={"Disease Name"}
        loading={loading}
        value={formik.values.disease_name || ""}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          setResourcePropsValues({
            ...resourcePropsValues,
            disease_name: newValue
          });
          formik.setFieldValue("disease_name", newValue?.toString() || "");
        }}
        error={
          formik.touched.disease_name && Boolean(formik.errors.disease_name)
        }
        helperText={
          (formik.touched.disease_name && formik.errors.disease_name) || ""
        }
      />
      <ResourceAutocomplete
        id='storage_condition_names'
        multiple
        resourceNames={resourcePropsNames?.storage_condition_names || []}
        label={"Storage Condition"}
        loading={loading}
        value={formik.values.storage_condition_names || []}
        optionsFilter={optionsFilter}
        onChange={(_, newValue) => {
          const updatedValue = Array.isArray(newValue) ? newValue : [newValue];
          setResourcePropsValues({
            ...resourcePropsValues,
            storage_condition_names: updatedValue
          });
          formik.setFieldValue("storage_condition_names", updatedValue);
        }}
        error={
          formik.touched.storage_condition_names &&
          Boolean(formik.errors.storage_condition_names)
        }
        helperText={
          (formik.touched.storage_condition_names &&
            formik.errors.storage_condition_names) ||
          ""
        }
      />
    </Stack>
  );
};

export default BiospecimenFields;
