import React, { FC, ReactNode } from "react";

import { Box } from "@mui/material";
import { ShipporiTypography } from "src/styles/components";

interface DashboardSidebarDesktopProps {
  children: ReactNode;
  institutionName: string;
  //eslint-disable-next-line
  loading: any;
}

const DashboardSidebarDesktop: FC<DashboardSidebarDesktopProps> = ({
  children,
  institutionName,
  loading
}) => (
  <div className='hidden md:flex md:shrink-0 pt-6 bg-white border'>
    <div className='flex flex-col w-72'>
      <div className='flex flex-col flex-grow pt-6 pb-3 h-screen overflow-y-auto'>
        <Box
          component='div'
          sx={{
            display: {
              xs: "none",
              sm: "block"
            },
            textAlign: "center",
            width: "100%",
            whiteSpace: "normal",
            overflowWrap: "break-word"
          }}
        >
          {loading.main ? (
            <ShipporiTypography
              textTransform={"uppercase"}
              fontSize={24}
              fontWeight={600}
              textAlign={"center"}
            >
              Loading... University
            </ShipporiTypography>
          ) : (
            <ShipporiTypography
              textTransform={"uppercase"}
              fontSize={24}
              fontWeight={600}
              textAlign={"center"}
            >
              {institutionName}
            </ShipporiTypography>
          )}
        </Box>

        <Box mb={5} mt={2} px={1}>
          <ShipporiTypography
            textTransform={"uppercase"}
            fontSize={14}
            fontWeight={500}
            textAlign={"center"}
          >
            RESOURCE SHARING PORTAL
          </ShipporiTypography>
        </Box>
        <div className='flex-grow flex flex-col'>{children}</div>
      </div>
    </div>
  </div>
);

export default DashboardSidebarDesktop;
