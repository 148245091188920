import getError from "@features/utils/get-error";
import User, {
  EditPhotoValues,
  UpdateFormValues,
  UploadPhotoValues
} from "@interfaces/user";
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

const defaultUser: User = {
  id: "",
  firstName: "",
  lastName: "",
  institutionName: "",
  labName: "",
  aboutMe: "",
  linkedInUrl: "",
  googleScholarUrl: "",
  researchGateUrl: "",
  position: "",
  profileImage: "",
  researchInterests: [],
  labId: "",
  institutionId: "",
  profilePhotoThumbnailKey: "",
  profilePhotoEditKey: ""
};

export const fetchUserProfile = createAsyncThunk(
  "profile/fetchUserProfile",
  async (userId: string, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.get(`/user?userId=${userId}`, {
        cancelToken: source.token
      });

      return response.data.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const updateProfile = createAsyncThunk(
  "proflie/updateProfile",
  async (values: UpdateFormValues, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await api.patch(`/user`, values, {
        cancelToken: source.token
      });

      return response.data.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const uploadPhoto = createAsyncThunk(
  "profile/uploadPhoto",
  async (values: UploadPhotoValues, { signal, rejectWithValue }) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const formData = new FormData();

      if (values.profile_photo instanceof File) {
        formData.append(
          "profile_photo",
          values.profile_photo,
          values.profile_photo.name
        );
      }

      if (values.edited_photo instanceof File) {
        formData.append(
          "edited_photo",
          values.edited_photo,
          values.edited_photo.name
        );
      }
      const response = await api.post("/media/profile-photo", formData, {
        cancelToken: source.token,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const updatePhoto = createAsyncThunk(
  "profile/updatePhoto",
  async (
    { values, userId }: { values: EditPhotoValues; userId: string },
    { signal, rejectWithValue }
  ) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const formData = new FormData();
      if (values.updated_photo instanceof File) {
        formData.append(
          "updated_photo",
          values.updated_photo,
          values.updated_photo.name
        );
      }
      const response = await api.put(
        `/media/profile-photo/${userId}`,
        formData,
        {
          cancelToken: source.token,
          headers: { "Content-Type": "multipart/form-data" }
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const removePhoto = createAsyncThunk(
  "profile/removePhoto",
  async (userId: string, { signal, rejectWithValue }) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await api.delete(`/media/profile-photo/${userId}`, {
        cancelToken: source.token,
        headers: { "Content-Type": "multipart/form-data" }
      });
      return response.data.data;
    } catch (error) {
      enqueueSnackbar("Could not delete photo, please try again", {
        variant: "error"
      });
      return rejectWithValue(getError(error));
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    user: defaultUser,
    status: "idle"
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchUserProfile.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "succeeded";
      })
      .addCase(updateProfile.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(uploadPhoto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        state.user.profilePhotoThumbnailKey = action.payload.thumbnail_key;
        state.user.profilePhotoEditKey = action.payload.edited_key;
      })
      .addCase(uploadPhoto.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updatePhoto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePhoto.fulfilled, (state, action) => {
        state.user.profilePhotoThumbnailKey = action.payload.thumbnail_key;
        state.user.profilePhotoEditKey = action.payload.edited_key;
      })
      .addCase(updatePhoto.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(removePhoto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removePhoto.fulfilled, (state) => {
        state.user.profilePhotoThumbnailKey = "";
        state.user.profilePhotoEditKey = "";
      })
      .addCase(removePhoto.rejected, (state) => {
        state.status = "failed";
      });
  }
});

export const { name, actions } = profileSlice;

/**
 * Selector function to get the profile slice from the Redux state.
 *
 * @param {RootState} state - The root Redux state.
 * @returns {InitialState} - The profile slice from the Redux state.
 */
const getSlice = (state: RootState) => state[name];

//Selector: Get the loading state.
export const getUserProfile = createSelector(
  getSlice,
  (slice) => slice?.user || defaultUser
);

export const getProfileStatus = createSelector(
  getSlice,
  (slice) => slice?.status || "idle"
);
