import React, { FC } from "react";
import { useSelector } from "react-redux";

import ResourceForm from "@components/Forms/ResourceForm";
import {
  addResourceValidationSchema,
  createResourceValidationSchema
} from "@features/validations/add-resource-validation";
import {
  FormikResourceValues,
  initialAddDialogValues
} from "@interfaces/resource-values";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";
import { APP_COLORS } from "src/styles/colors";

import { selectSkipValidation } from "../resourceSlice";

interface AddResourceDialogProps {
  open: boolean;
  handleClose: () => void;
  initialValues?: FormikResourceValues;
  isInstitution: boolean;
  editing?: boolean;
  postEdit?: boolean;
}

const AddResourceDialog: FC<AddResourceDialogProps> = ({
  open,
  handleClose,
  initialValues,
  isInstitution,
  editing = false,
  postEdit = false
}) => {
  const skipValidation = useSelector(selectSkipValidation);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "700px"
          }
        }
      }}
    >
      <DialogTitle sx={{ borderBottom: `1px solid ${APP_COLORS.mediumGray}` }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>
            {editing
              ? "Edit Resource"
              : isInstitution
                ? "Add Resource to your institution"
                : "Add Resource to your lab"}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <ResourceForm
        initialValues={
          editing
            ? (initialValues as FormikResourceValues)
            : initialAddDialogValues
        }
        inDialog={!postEdit}
        validationSchema={() =>
          !postEdit
            ? addResourceValidationSchema(skipValidation)
            : createResourceValidationSchema(skipValidation)
        }
        onSubmit={handleClose}
        editing={editing}
      />
    </Dialog>
  );
};

export default AddResourceDialog;
