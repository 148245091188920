import React from "react";

import clsx from "@features/utils/clsx";
import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage
} from "@mui/icons-material";
import { Typography } from "@mui/material";

interface PaginationProps {
  className?: string;
  total: number;
  page: number;
  limit: number;
  totalPages: number;
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  setPage: (page: number) => unknown;
}

const Pagination: React.FC<PaginationProps> = ({
  className,
  total,
  totalPages,
  page,
  setPage,
  loading
}) => {
  const rootClass = clsx(
    "bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6",
    className
  );

  const [buttonPages, setButtonPages] = React.useState<number[]>([1]);

  const getPageNumbers = React.useMemo(() => {
    if (totalPages === 0) {
      return [1]; // Default to show 1 button if totalPages is 0
    }

    const pagesToShow = 5;
    let startPage = 1,
      endPage = 1;

    if (totalPages <= pagesToShow) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (page <= 3) {
        startPage = 1;
        endPage = pagesToShow;
      } else if (page + 2 >= totalPages) {
        startPage = totalPages - pagesToShow + 1;
        endPage = totalPages;
      } else {
        startPage = page - 2;
        endPage = page + 2;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  }, [totalPages, page]);

  React.useEffect(() => {
    setButtonPages(getPageNumbers);
  }, [page, totalPages]);

  return (
    <nav className={rootClass} aria-label='Pagination'>
      <div className='flex-1 flex justify-center sm:justify-center gap-3 flex-wrap'>
        <button
          onClick={() => setPage(1)}
          disabled={total === 0 || page === 1 || loading}
          className={clsx(
            "w-5 h-5 border bg-gray-100 text-gray-400 rounded flex items-center justify-center p-3",
            {
              "text-gray-300 bg-white": total === 0 || page === 1,
              "hover:border-teal-500": total !== 0 && page > 1
            }
          )}
        >
          <FirstPage />
        </button>
        <button
          onClick={() => setPage(page - 1)}
          disabled={total === 0 || page === 1 || loading}
          className={clsx(
            "w-5 h-5 border bg-gray-100 text-gray-400 rounded flex items-center justify-center p-3",
            {
              "text-gray-300 bg-white": total === 0 || page === 1,
              "hover:border-teal-500": total !== 0 && page > 1
            }
          )}
        >
          <ChevronLeft />
        </button>
        {buttonPages.map((pageNum, index) => {
          return (
            <button
              key={index}
              className={clsx(
                "hover:border-teal-500 w-5 h-5 border rounded flex items-center justify-center p-3",
                {
                  "border-gray-500": pageNum === page
                }
              )}
              disabled={pageNum === page || loading}
              onClick={() => setPage(pageNum)}
            >
              <Typography>{pageNum}</Typography>
            </button>
          );
        })}
        <button
          data-testid='paginate-next'
          onClick={() => setPage(page + 1)}
          disabled={total === 0 || page === totalPages || loading}
          className={clsx(
            "w-5 h-5 border bg-gray-100 text-gray-400 rounded flex items-center justify-center p-3",
            {
              "text-gray-300 bg-white": total === 0 || page === totalPages,
              "hover:border-teal-500": total !== 0 && page !== totalPages
            }
          )}
        >
          <ChevronRight />
        </button>
        <button
          data-testid='paginate-next'
          onClick={() => setPage(totalPages)}
          disabled={total === 0 || page === totalPages || loading}
          className={clsx(
            "w-5 h-5 border bg-gray-100 text-gray-400 rounded flex items-center justify-center p-3",
            {
              "text-gray-300 bg-white": total === 0 || page === totalPages,
              "hover:border-teal-500": total !== 0 && page !== totalPages
            }
          )}
        >
          <LastPage />
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
