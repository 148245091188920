import {
  removePhoto,
  updatePhoto,
  uploadPhoto
} from "@features/Profile/profileSlice";
import { constants } from "@features/utils/constants";
import getError from "@features/utils/get-error";
import { removeNullValues } from "@features/utils/remove-null-values";
import {
  FormikResourceValues,
  ResourceProperties,
  ResourceValues
} from "@interfaces/resource-values";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import api, { cancelToken } from "src/services/api";
import { RootState } from "src/services/store";

export const addResource = createAsyncThunk(
  "resources/addResource",
  async (resourceData: FormikResourceValues, { rejectWithValue, getState }) => {
    const state = getState() as RootState;

    if (resourceData.category === constants.animalModel || constants.cellLine) {
      const { user } = state.auth;

      resourceData = {
        ...resourceData,
        contact_user_id: user.id,
        lab_id: user.labId,
        institution_id: user.institutionId
      };
    }

    const cleanData = removeNullValues(resourceData);

    try {
      const response = await api.post(`/resource`, cleanData);
      enqueueSnackbar("Resource Created", { variant: "success" });
      return { href: resourceData.category, model: response.data.data };
    } catch (err) {
      enqueueSnackbar("Resource Not Created", { variant: "error" });
      return rejectWithValue(getError(err));
    }
  }
);

interface EditResource extends FormikResourceValues {
  created_at?: string;
}

export const editResource = createAsyncThunk(
  "resources/editResource",
  async (
    {
      resourceId,
      resourceData
    }: { resourceId: string; resourceData: EditResource },
    { rejectWithValue }
  ) => {
    const cleanData = removeNullValues(resourceData);

    // this gets added when it's a post being edited resource
    if (cleanData.created_at) {
      delete cleanData.created_at;
    }

    try {
      const response = await api.patch(`/resource/${resourceId}`, cleanData);
      enqueueSnackbar("Resource Updated", { variant: "success" });
      return { id: resourceId, model: response.data.data };
    } catch (err) {
      enqueueSnackbar("Resource Not Updated", { variant: "error" });
      return rejectWithValue(getError(err));
    }
  }
);

interface ResourceRequest {
  category?: string;
  sources?: string;
  institution_id?: string;
  lab_id?: string;
  user_id?: string;
  page?: number;
  limit?: number;
  search?: string;
  public?: boolean;
}

export const fetchResources = createAsyncThunk(
  "resources/fetchResources",
  async (
    { params, append }: { params: ResourceRequest; append?: boolean },
    { signal, rejectWithValue, getState }
  ) => {
    const source = cancelToken.source();
    const state = getState() as RootState;
    const sortOption = state.resources.sortOption;

    // Extracting the current URL
    const currentUrl = new URL(window.location.href);
    const searchQuery = currentUrl.searchParams.get("query");

    const sortingParams = {
      order_dir: sortOption === "recent" ? "desc" : "asc",
      ...(searchQuery && !params.search ? { search: searchQuery } : {})
    };

    const requestParams = { ...params, ...sortingParams };

    signal.addEventListener("abort", () => source.cancel());
    try {
      const { data: response } = await api.get("/resources", {
        params: requestParams
      });

      // If a search was conducted, emit a message
      if (params.search) {
        const responseLength: number = response.data.resources?.length || 0;
        const successMessage =
          responseLength > 0
            ? `Showing ${responseLength} ${responseLength === 1 ? "resource" : "resources"} matching "${params.search}"`
            : `No results for "${params.search}".`;
        const snackBarVariant = responseLength > 0 ? "success" : "warning";

        enqueueSnackbar(successMessage, { variant: snackBarVariant });
      }

      return {
        resources: response.data?.resources ?? [],
        total_pages: response.metadata?.total_pages ?? 0,
        totalCount: response.metadata?.total_count ?? 0,
        current_page: response.metadata?.page ?? 1,
        append: append ?? false
      };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  },
  {
    condition: ({ params }: { params: ResourceRequest }) => {
      /**
       * Don't fetch if there is no category or sources
       * If the search is being done within a home page,
       * then category is not required
       */
      if (params.sources === constants.homeResource) {
        return true;
      }

      if (!params.category || !params.sources) {
        return false;
      }

      /**
       * Fetch
       */
      return true;
    }
  }
);

export const deleteResource = createAsyncThunk(
  "resources/deleteResource",
  async (postId: string, { signal, rejectWithValue }) => {
    const source = cancelToken.source();

    signal.addEventListener("abort", () => source.cancel());

    try {
      await api.delete(`/resource/${postId}`, {
        cancelToken: source.token
      });
      enqueueSnackbar("Deleted post", { variant: "success" });
      return postId;
    } catch (error) {
      const generatedError = getError(error);
      enqueueSnackbar("Deleted post", { variant: "success" });
      return rejectWithValue(generatedError);
    }
  }
);

export const fetchResourcesProperties = createAsyncThunk(
  "resources/fetchResourcesProperties",
  async (_, { signal, rejectWithValue }) => {
    const source = cancelToken.source();
    signal.addEventListener("abort", () => source.cancel());

    try {
      const { data: response } = await api.get("/resource-properties");
      return {
        resources_properties: response.data ?? []
      };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const searchExternalDb = createAsyncThunk(
  "resources/searchExternalDb",
  async (
    params: { database: string; id?: string },
    { signal, rejectWithValue }
  ) => {
    if (!params.id) return false;
    const source = cancelToken.source();
    signal.addEventListener("abort", () => source.cancel());

    try {
      const { data: response } = await api.get("resource/search_external_db", {
        params
      });

      const result = {
        ...response.data,
        species_name: response.data.species_name
          ? response.data.species_name
          : "mouse"
      };

      return {
        resources_properties: result ?? []
      };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

export const searchResourceName = createAsyncThunk(
  "resource/name",
  async (params: { name: string }, { signal, rejectWithValue }) => {
    if (!params) return false;
    const source = cancelToken.source();
    signal.addEventListener("abort", () => source.cancel());

    try {
      const { data: response } = await api.get("resource", { params });
      const result = { ...response.data, species_name: "mouse" };
      return { resources_properties: result ?? [] };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

// for live jax/mgi search feature
export const fetchMatchingExternalIds = createAsyncThunk(
  "resources/fetchMatchingExternalIds",
  async (
    params: { database: string; searchTerm: string },
    { signal, rejectWithValue }
  ) => {
    if (!params.searchTerm) return false;
    const source = cancelToken.source();
    signal.addEventListener("abort", () => source.cancel());

    try {
      const { data: response } = await api.get(
        "/resource/get_matching_external_id",
        {
          params: {
            database: params.database,
            id: params.searchTerm
          },
          cancelToken: source.token
        }
      );
      return {
        database: params.database,
        results: response.data.results || []
      };
    } catch (error) {
      const generatedError = getError(error);
      return rejectWithValue(generatedError);
    }
  }
);

interface ResourceState {
  data: ResourceValues[];
  propertiesData: ResourceProperties;
  loading: boolean;
  total_pages: number;
  totalCount: number;
  current_page: number;
  sortOption: string;
  href: keyof typeof constants;
  query: string;
  searchLoading: boolean;
  searchDone: boolean;
  searchError: unknown;
  searchResults: Record<string, string | string[]>;
  dbSearchDone: boolean;
  skipValidation: boolean;
  dbResults: null | {
    mutation_type_names: string[];
    species_name: string;
    strain_name: string;
    target_gene_names: string[];
    cell_line_name: string;
    tissue_name: string;
    disease_name: string;
    morphology: string;
  };
}

const initialState: ResourceState = {
  data: [],
  searchResults: {},
  searchLoading: false,
  searchError: null,
  searchDone: false,
  propertiesData: {
    strainName: [],
    name: [],
    cellLineName: [],
    species_name: [],
    mutation_type_names: [],
    storage_condition_names: [],
    target_gene_names: [],
    tissue_name: [],
    disease_name: [],
    mutation_names: [],
    jax_external_id: [],
    mgi_external_id: [],
    synonyms: [],
    atcc_external_id: [],
    morphology: []
  },
  skipValidation: false,
  total_pages: 0,
  totalCount: 0,
  current_page: 1,
  href: "animal_models",
  loading: false,
  sortOption: "recent",
  query: "",
  dbSearchDone: false,
  dbResults: null
};

export const resourceSlice = createSlice({
  name: "resources",
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.current_page = action.payload;
    },
    setHref(state, action) {
      state.href = action.payload;
    },
    setSortOption: (state, action) => {
      state.sortOption = action.payload;
    },
    resetResources: (state) => {
      state.data = [];
      state.current_page = 1;
      state.total_pages = 0;
      state.totalCount = 0;
      state.searchResults = {};
      state.searchError = null;
      state.searchDone = false;
      state.skipValidation = false;
    },
    setQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
    clearSearchResults(state) {
      state.searchResults = {};
      state.searchError = null;
      state.searchDone = false;
      state.dbResults = null;
      state.dbSearchDone = false;
      state.skipValidation = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResources.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchResources.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload.append) {
          state.data = _.unionBy(state.data, action.payload.resources, "id");
        } else {
          state.data = action.payload.resources;
        }

        state.total_pages = action.payload.total_pages;
        state.totalCount = action.payload.totalCount;
        state.current_page = action.payload.current_page;
      })
      .addCase(fetchResources.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchResourcesProperties.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchResourcesProperties.fulfilled,
        (
          state,
          action: PayloadAction<{
            resources_properties: ResourceProperties;
          }>
        ) => {
          state.loading = false;
          state.propertiesData = action.payload.resources_properties;
        }
      )
      .addCase(fetchResourcesProperties.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addResource.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [...state.data, action.payload.model];
      })
      .addCase(
        deleteResource.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.data = state.data.filter(
            (resource) => resource.id !== action.payload
          );
        }
      )
      .addCase(searchExternalDb.pending, (state) => {
        state.searchLoading = true;
        state.searchError = null;
        state.dbSearchDone = false;
        state.skipValidation = false;
      })
      .addCase(searchExternalDb.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.dbSearchDone = true;
        if (action.payload) {
          state.dbResults = action.payload.resources_properties;
          if (action.payload.resources_properties.strain_name)
            state.skipValidation = true;
        }
      })
      .addCase(searchExternalDb.rejected, (state, action) => {
        state.searchLoading = false;
        state.dbSearchDone = false;
        state.searchError = action.payload;
        state.skipValidation = false;
      })
      .addCase(searchResourceName.pending, (state) => {
        state.searchLoading = true;
        state.searchError = null;
        state.searchDone = false;
      })
      .addCase(searchResourceName.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.searchDone = true;
        if (action.payload) {
          state.searchResults = action.payload.resources_properties;
          for (const key in action.payload.resources_properties) {
            state.propertiesData[
              key as keyof typeof state.propertiesData
            ]?.push(action.payload.resources_properties[key]);
          }
        }
      })
      .addCase(searchResourceName.rejected, (state, action) => {
        state.searchLoading = false;
        state.searchError = action.payload;
        state.searchDone = false;
      })
      .addCase(fetchMatchingExternalIds.pending, (state) => {
        state.searchLoading = true;
        state.searchError = null;
      })
      .addCase(fetchMatchingExternalIds.fulfilled, (state, action) => {
        state.searchLoading = false;
        if (action.payload) {
          const { database, results } = action.payload;
          if (database === "jax") {
            state.propertiesData.jax_external_id = results;
          } else if (database === "mgi") {
            state.propertiesData.mgi_external_id = results;
          }
        }
      })
      .addCase(fetchMatchingExternalIds.rejected, (state, action) => {
        state.searchLoading = false;
        state.searchError = action.payload;
      })
      .addCase(editResource.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.data.findIndex(
          (resource) => resource.id === action.payload.id
        );
        if (index !== -1) {
          state.data[index] = action.payload.model;
        }
      })
      .addCase(editResource.rejected, (state) => {
        state.loading = false;
      })
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        state.data.forEach((item) => {
          if (
            item.contact_user_details &&
            "profile_photo_thumbnail_key" in item.contact_user_details
          ) {
            item.contact_user_details.profile_photo_thumbnail_key =
              action.payload.thumbnail_key;
          }
        });
      })
      .addCase(updatePhoto.fulfilled, (state, action) => {
        state.data.forEach((item) => {
          if (
            item.contact_user_details &&
            "profile_photo_thumbnail_key" in item.contact_user_details
          ) {
            item.contact_user_details.profile_photo_thumbnail_key =
              action.payload.thumbnail_key;
          }
        });
      })
      .addCase(removePhoto.fulfilled, (state) => {
        state.data.forEach((item) => {
          if (
            item.contact_user_details &&
            "profile_photo_thumbnail_key" in item.contact_user_details
          ) {
            item.contact_user_details.profile_photo_thumbnail_key = "";
          }
        });
      });
  }
});

export const { name, actions } = resourceSlice;

export const {
  setPage,
  setHref,
  setSortOption,
  resetResources,
  setQuery,
  clearSearchResults
} = actions;

const getSlice = (state: RootState) => state[name];

export const selectResources = createSelector(
  getSlice,
  (slice) => slice?.data || []
);
export const selectResourcesProperties = createSelector(
  getSlice,
  (slice) =>
    slice?.propertiesData || {
      strainName: [],
      name: [],
      cellLineName: [],
      species_name: [],
      mutation_type_names: [],
      storage_condition_names: [],
      target_gene_names: [],
      tissue_name: [],
      disease_name: [],
      mutation_names: [],
      jax_external_id: [],
      mgi_external_id: [],
      synonyms: [],
      atcc_external_id: [],
      morphology: []
    }
);
export const selectTotalPages = createSelector(
  getSlice,
  (slice) => slice?.total_pages || 0
);

export const selectTotalCount = createSelector(
  getSlice,
  (slice) => slice?.totalCount || 0
);

export const selectCurrentPage = createSelector(
  getSlice,
  (slice) => slice?.current_page || 1
);

export const selectHref = createSelector(
  getSlice,
  (slice) => slice?.href || "animal_models"
);

export const selectSortOption = createSelector(
  getSlice,
  (slice) => slice?.sortOption || "recent"
);

export const getFetchResourceLoading = createSelector(
  getSlice,
  (slice) => slice?.loading || false
);

export const selectResourceQuery = createSelector(
  getSlice,
  (slice) => slice.query || ""
);

export const selectSearchResults = createSelector(
  getSlice,
  (slice) => slice.searchResults || {}
);

export const selectSearchDone = createSelector(
  getSlice,
  (slice) => slice?.searchDone || false
);

export const selectDBSearchResults = createSelector(
  getSlice,
  (slice) => slice.dbResults || null
);

export const selectDBSearchDone = createSelector(
  getSlice,
  (slice) => slice?.dbSearchDone || false
);

export const selectSkipValidation = createSelector(
  getSlice,
  (slice) => slice?.skipValidation || false
);
