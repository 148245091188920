import { RefObject, useEffect, useRef } from "react";

type CallbackFunction = (event: MouseEvent | KeyboardEvent) => void;

function useClickAway<T extends HTMLElement = HTMLElement>(
  fn: CallbackFunction,
  active: boolean
): RefObject<T> {
  const ref = useRef<T>(null);
  const callback = useRef<CallbackFunction>(fn);

  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  useEffect(() => {
    if (!active) {
      return;
    }

    const onKeyUp = (event: KeyboardEvent) => {
      if (callback.current && (event.key === "Escape" || event.which === 27)) {
        callback.current(event);
      }
    };

    const onClick = (event: MouseEvent) => {
      const current = ref.current;
      const target = event.target as Node;

      if (current && (target === current || current.contains(target))) {
        return;
      }

      if (callback.current) {
        callback.current(event);
      }
    };

    /**
     * Debounce binding of events until next time (so that the initial click to open the modal isn't captured)
     */
    const timeoutId = setTimeout(() => {
      document.body.addEventListener("click", onClick);
      window.addEventListener("keyup", onKeyUp);
    }, 0);

    return () => {
      /**
       * Probably not necessary to clear this timeout, but just in case it fires after unmount.
       */
      clearTimeout(timeoutId);
      document.body.removeEventListener("click", onClick);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, [active]);

  return ref;
}

export default useClickAway;
