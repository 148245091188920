const FormatMessageTime = (utcTime: string | number | Date) => {
  const messageDate = new Date(utcTime);
  const now = new Date();

  if (messageDate.toDateString() === now.toDateString()) {
    return "Today";
  }

  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  if (messageDate.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  }

  return messageDate.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  });
};
export default FormatMessageTime;
