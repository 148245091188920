import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Sort from "@components/Sort/Sort";
import { getUser } from "@features/Auth/authSlice";
import EntryList from "@features/Posts/components/EntryList";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Card, Stack, Typography } from "@mui/material";
import { DashboardContent } from "src/layout/dashboard";
import { APP_COLORS } from "src/styles/colors";

const InstitutionalHomepage = () => {
  const currentUser = useSelector(getUser);
  return (
    <DashboardContent className='lg:px-36'>
      <Stack spacing={3}>
        <Box>
          <Box className='rounded-xl'>
            <Card variant='outlined' className='pr-2 sm:pr-4 pl-2 sm:pl-0 mt-5'>
              <div className='flex flex-col items-center p-5 pb-2'>
                <Typography
                  fontWeight='bold'
                  fontSize='1.2rem'
                  textAlign='center'
                >
                  Discover and share animal models, cell lines & other resources
                  at your institution
                </Typography>
                <Typography textAlign='center'>
                  Or share your own resources to collaborate with other
                  researchers!
                </Typography>
                <div className='flex flex-row justify-center gap-4 py-4 w-full'>
                  <Link to='/dashboard/create' className='w-auto'>
                    <button
                      className={`px-3 py-1 bg-white text-[${APP_COLORS.lightGreen}] border border-[${APP_COLORS.lightGreen}] rounded-xl flex items-center`}
                    >
                      <AddIcon
                        color='primary'
                        className='mr-1'
                        sx={{ width: "18px" }}
                      />{" "}
                      Create a post
                    </button>
                  </Link>
                  <Link to='institution-database' className='w-auto'>
                    <button
                      className={`px-3 py-1 bg-white text-[${APP_COLORS.lightGreen}] border border-[${APP_COLORS.lightGreen}] rounded-xl flex items-center`}
                    >
                      <SearchIcon
                        color='primary'
                        className='mr-1'
                        sx={{ width: "18px" }}
                      />{" "}
                      Search database
                    </button>
                  </Link>
                </div>
              </div>
            </Card>
          </Box>
        </Box>
        <Sort />
        <EntryList
          instId={currentUser.institutionId}
          scrollContainer='#dashboard-content'
          renderSource='institution'
        />
      </Stack>
    </DashboardContent>
  );
};

export default InstitutionalHomepage;
