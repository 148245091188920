import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ResourceAutocomplete } from "@components/AutoComplete/ResourceAutocomplete";
import CategorySelector from "@components/CategorySelector/CategorySelector";
import { getUser } from "@features/Auth/authSlice";
import {
  addResource,
  clearSearchResults,
  editResource,
  fetchResourcesProperties,
  selectResourcesProperties,
  selectSearchDone,
  selectSearchResults
} from "@features/InsitutionDatabase/resourceSlice";
import {
  getInstitutionById,
  selectInstitutionById
} from "@features/Lab/labslice";
import { optionsFilter } from "@features/utils/automplete-filter";
import { constants } from "@features/utils/constants";
import { transformToInitialValues } from "@features/utils/transform-to-initial";
import {
  transformArray,
  transformResourceData
} from "@features/utils/transformResourceData";
import { CreateResourceFormValues } from "@interfaces/create-resource-form";
import {
  FormikResourceValues,
  initialAddDialogValues,
  ResourceProperties
} from "@interfaces/resource-values";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { FormikTouched, useFormik } from "formik";
import { AppDispatch } from "src/services/store";
import { theme } from "src/styles/theme";

import AnimalModelFields from "./AnimalModelFields";
import AntiBodyFields from "./AntibodyFields";
import BiospecimenFields from "./BiospecimenFields";
import CellLineFields from "./CellLineFields";
import EquipmentFields from "./EquipmentFields";
import OtherFields from "./OtherFields";
import PlasmidFields from "./PlasmidFields";
interface ResourceFormProps {
  initialValues: CreateResourceFormValues | FormikResourceValues;
  onSubmit?: () => void;
  inDialog?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: any;
  editing?: boolean;
}

const ResourceForm: React.FC<ResourceFormProps> = ({
  onSubmit,
  initialValues = initialAddDialogValues,
  inDialog = false,
  validationSchema,
  editing = false
}) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const currentUser = useSelector(getUser);
  const institution = useSelector(selectInstitutionById);

  const [postIsPublic, setPostIsPublic] = useState(
    institution?.partnership?.default_public
  );

  const resources_properties = useSelector(selectResourcesProperties);
  const searchResults = useSelector(selectSearchResults);
  const searchDone = useSelector(selectSearchDone);

  const [resourcePropsValues, setResourcePropsValues] =
    useState<ResourceProperties>({});
  const [resourcePropsNames, setResourcePropsNames] =
    useState<ResourceProperties>({});
  const [loading, setLoading] = useState<boolean>(false);

  // state to manage the disabled state of fields
  const [disabledFields, setDisabledFields] = useState({
    strainName: false,
    mutation_type_names: false,
    target_gene_names: false,
    species_name: false
  });

  const [resourceCategory, setResourceCategory] = useState(
    initialValues?.category || constants.animalModel
  );

  useEffect(() => {
    setLoading(true);
    dispatch(fetchResourcesProperties());

    dispatch(clearSearchResults());
    setDisabledFields({
      strainName: false,
      mutation_type_names: false,
      target_gene_names: false,
      species_name: false
    });
  }, [dispatch]);

  useEffect(() => {
    if (resources_properties) {
      setResourcePropsNames(transformArray(resources_properties));
      setLoading(false);
    }
  }, [resources_properties]);

  const formik = useFormik({
    initialValues: !editing
      ? initialValues
      : transformToInitialValues(initialValues, initialAddDialogValues),
    validationSchema,

    enableReinitialize: true, // Reinitializes form values when initialValues change
    onSubmit: async (values) => {
      try {
        const resourceData = transformResourceData(values);
        if (editing && initialValues.id) {
          await dispatch(
            editResource({ resourceId: initialValues.id, resourceData })
          );
        } else {
          await dispatch(addResource(resourceData)).unwrap();
        }
        formik.resetForm();
        setResourcePropsValues({});
        if (!inDialog && !editing) {
          navigate("/dashboard/home");
        } else if (onSubmit) {
          onSubmit();
        }
      } finally {
        formik.setSubmitting(false);
      }
    }
  });

  // if the search db state holding jax/mgi results is not empty, populate the fields
  useEffect(() => {
    if (searchDone) {
      formik.setFieldValue("strainName", searchResults.strain_name || "");
      setDisabledFields((prev) => ({
        ...prev,
        strainName: !!searchResults.strain_name
      }));

      if (searchResults.mutation_type_names?.length) {
        setResourcePropsValues({
          ...resourcePropsValues,
          mutation_type_names: searchResults.mutation_type_names as string[]
        });
        formik.setFieldValue(
          "mutation_type_names",
          searchResults.mutation_type_names
        );
        setDisabledFields((prev) => ({ ...prev, mutation_type_names: true }));
      }

      if (searchResults.target_gene_names?.length) {
        formik.setFieldValue(
          "target_gene_names",
          searchResults.target_gene_names
        );
        setDisabledFields((prev) => ({ ...prev, target_gene_names: true }));
      }
      if (searchResults.species_name?.length) {
        formik.setFieldValue("species_name", searchResults.species_name);
        setDisabledFields((prev) => ({ ...prev, species_name: true }));
      }
      //when search is based on strain Name field
      if (searchResults.name?.length) {
        formik.setFieldValue("strainName", searchResults.name);
      }

      formik.validateForm();
    }
  }, [searchDone]);

  // category drop down
  const handleCategoryChange = (event: SelectChangeEvent<unknown>) => {
    const newCategory = event.target.value as string;
    setResourceCategory(newCategory);
    formik.setFieldValue("category", newCategory);
  };

  useEffect(() => {
    dispatch(getInstitutionById({ institutionId: currentUser.institutionId }));
  }, [dispatch, currentUser.institutionId]);

  useEffect(() => {
    if (
      institution.is_partner &&
      institution.partnership?.default_public !== undefined
    ) {
      setPostIsPublic(institution.partnership.default_public);
    }
  }, [institution]);

  const handlePostToggle = () => {
    const newValue = !postIsPublic;
    setPostIsPublic(newValue);
    formik.setFieldValue("public", newValue);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent>
        <Stack spacing={4}>
          {!inDialog && (
            <>
              <Box>
                <Stack
                  direction={"row"}
                  alignContent={"center"}
                  justifyContent={"left"}
                  gap={2}
                >
                  <Typography variant='subtitle1' textAlign='left' mb={0}>
                    I am<sup className='text-red-500 font-bold'>*</sup>
                  </Typography>
                  <Button
                    sx={{
                      borderRadius: 2,
                      ...(formik.errors.source
                        ? { borderColor: "#f44336" }
                        : {})
                    }}
                    variant={
                      formik.values.source === "user_post_share"
                        ? "shareContained"
                        : "shareOutlined"
                    }
                    onClick={() =>
                      formik.setFieldValue("source", "user_post_share")
                    }
                  >
                    Sharing
                  </Button>
                  <Button
                    sx={{
                      borderRadius: 2,
                      ...(formik.errors.source
                        ? { borderColor: "#f44336" }
                        : {})
                    }}
                    variant={
                      formik.values.source === "user_post_request"
                        ? "requestContained"
                        : "requestOutlined"
                    }
                    onClick={() =>
                      formik.setFieldValue("source", "user_post_request")
                    }
                  >
                    Requesting
                  </Button>
                </Stack>
                {formik.errors.source && (
                  <Typography
                    component='small'
                    ml={"14px"}
                    mt={"3px"}
                    lineHeight={1.66}
                    fontSize={"0.75rem"}
                    color='error'
                  >
                    {formik.errors.source}
                  </Typography>
                )}
              </Box>
              {!inDialog && (
                <Box>
                  <TextField
                    fullWidth
                    id='title'
                    name='title'
                    label={
                      <Typography>
                        Listing Title
                        <sup className='text-red-500 font-bold'>*</sup>
                      </Typography>
                    }
                    value={(formik.values as CreateResourceFormValues).title}
                    onChange={formik.handleChange}
                    error={
                      (
                        formik.touched as FormikTouched<CreateResourceFormValues>
                      ).title &&
                      Boolean((formik.errors as CreateResourceFormValues).title)
                    }
                    helperText={
                      (
                        formik.touched as FormikTouched<CreateResourceFormValues>
                      ).title &&
                      (formik.errors as CreateResourceFormValues).title
                    }
                    placeholder='e.g., Looking for a mouse for my cholesterol experiment'
                  />
                </Box>
              )}
            </>
          )}

          <CategorySelector
            handleCategoryChange={handleCategoryChange}
            resourceCategory={resourceCategory}
          />

          {formik.values.category === constants.animalModel && (
            <AnimalModelFields
              disabledFields={disabledFields}
              setDisabledFields={setDisabledFields}
              formik={formik}
              loading={loading}
              inDialog={inDialog}
              initialValues={initialValues}
              resourcePropsNames={resourcePropsNames}
              setResourcePropsValues={setResourcePropsValues}
              resourcePropsValues={resourcePropsValues}
            />
          )}

          {formik.values.category === constants.cellLine && (
            <CellLineFields
              formik={formik}
              inDialog={inDialog}
              resourcePropsNames={resourcePropsNames}
              loading={loading}
              setResourcePropsValues={setResourcePropsValues}
              resourcePropsValues={resourcePropsValues}
            />
          )}

          {formik.values.category === constants.biospecimen && (
            <BiospecimenFields
              formik={formik}
              resourcePropsNames={resourcePropsNames}
              loading={loading}
              setResourcePropsValues={setResourcePropsValues}
              resourcePropsValues={resourcePropsValues}
            />
          )}

          {formik.values.category === constants.plasmid && (
            <PlasmidFields
              formik={formik}
              resourcePropsNames={resourcePropsNames}
              loading={loading}
              setResourcePropsValues={setResourcePropsValues}
              resourcePropsValues={resourcePropsValues}
            />
          )}

          {formik.values.category === constants.antibody && (
            <AntiBodyFields
              isMobile={isMobile}
              formik={formik}
              resourcePropsNames={resourcePropsNames}
              loading={loading}
              setResourcePropsValues={setResourcePropsValues}
              resourcePropsValues={resourcePropsValues}
            />
          )}

          {formik.values.category === constants.equipment && (
            <EquipmentFields
              formik={formik}
              resourcePropsNames={resourcePropsNames}
              loading={loading}
              setResourcePropsValues={setResourcePropsValues}
              resourcePropsValues={resourcePropsValues}
            />
          )}

          {formik.values.category === constants.other && (
            <OtherFields
              formik={formik}
              resourcePropsNames={resourcePropsNames}
              loading={loading}
              setResourcePropsValues={setResourcePropsValues}
              resourcePropsValues={resourcePropsValues}
            />
          )}

          <TextField
            fullWidth
            variant='outlined'
            id='description'
            name='description'
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            placeholder={
              inDialog
                ? "What is the disease model? What is the quantity? Give as much info as is helpful!"
                : "Enter a description"
            }
            multiline
            minRows={inDialog ? 3 : 5}
            label={inDialog ? "Details/Comments" : <>Description</>}
          />
          {!inDialog && (
            <ResourceAutocomplete
              id={"tag_names"}
              multiple
              value={formik.values?.tag_names || []}
              resourceNames={resourcePropsNames?.tags || []}
              label={"Tags"}
              loading={loading}
              optionsFilter={optionsFilter}
              onChange={(_, newValue) => {
                const updatedValue = Array.isArray(newValue)
                  ? newValue
                  : [newValue];
                setResourcePropsValues({
                  ...resourcePropsValues,
                  tag_names: updatedValue
                });
                formik.setFieldValue("tag_names", updatedValue);
              }}
              error={
                (formik.touched as FormikTouched<CreateResourceFormValues>)
                  .tag_names &&
                Boolean((formik.errors as CreateResourceFormValues).tag_names)
              }
              helperText={
                ((formik.touched as FormikTouched<CreateResourceFormValues>)
                  .tag_names &&
                  (formik.errors as CreateResourceFormValues).tag_names) ||
                ""
              }
            />
          )}
          {!inDialog && (
            <Box>
              <Typography variant='body1' sx={{ mb: 1, fontWeight: "bold" }}>
                Post visibility
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Typography variant='body2'>My institution only</Typography>
                {!institution.is_partner ? (
                  <Switch
                    checked={true}
                    disableRipple
                    inputProps={{ "aria-label": "post visibility toggle" }}
                    sx={{
                      "& .MuiSwitch-thumb": {
                        color: "#bdbdbd"
                      },
                      "& .MuiSwitch-track": {
                        opacity: 0.5,
                        backgroundColor: "#e0e0e0 !important"
                      },
                      "&:hover": {
                        cursor: "default"
                      }
                    }}
                  />
                ) : (
                  <Switch
                    checked={postIsPublic} // Reflect the current state
                    onChange={handlePostToggle} // Toggle on change
                    inputProps={{ "aria-label": "post visibility toggle" }}
                  />
                )}
                <Typography variant='body2'>Visible to everyone</Typography>
                {/* {postIsPublic && <p>Hey there</p>} */}
              </Box>
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        {inDialog && (
          <>
            <Button onClick={onSubmit} color='secondary'>
              Cancel
            </Button>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              fullWidth={isMobile ? true : false}
              disabled={!formik.isValid || formik.isSubmitting}
            >
              {editing ? "Save" : "Add"}
            </Button>
          </>
        )}
        {!inDialog && (
          <Box width={"100%"} textAlign={"right"} px={2}>
            <Button
              variant='contained'
              type='submit'
              sx={{ borderRadius: 2 }}
              fullWidth={isMobile ? true : false}
              disabled={!formik.isValid || formik.isSubmitting}
            >
              {formik.isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        )}
      </DialogActions>
    </form>
  );
};

export default ResourceForm;
