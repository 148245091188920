import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getUser } from "@features/Auth/authSlice";
import { isSSOUser } from "@features/utils/is-sso-user";
import { selectAppConfig } from "src/config/configSlice";
import { RootState } from "src/services/store";

interface Options {
  redirect: boolean;
}

const useRequireSession = (options: Options) => {
  const { redirect = true } = options;
  const user = useSelector((state: RootState) => getUser(state));
  const navigate = useNavigate();

  const appConfig = useSelector(selectAppConfig);

  const { id } = user;
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      if (
        user.emailVerified ||
        isSSOUser(user.email) ||
        (appConfig && appConfig.APP_ENVIRONMENT === "development") ||
        appConfig.APP_ENVIRONMENT === "staging"
      ) {
        return;
      } else return navigate("/limited-access");
    }

    if (redirect) {
      navigate("/limited-access");
    }
  }, [dispatch, redirect, id]);

  return useMemo(() => [id], [id]);
};

export default useRequireSession;
