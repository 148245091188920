import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUser } from "@features/Auth/authSlice";
import {
  getParticipantRooms,
  getRoomMessages,
  markMessagesAsRead,
  selectParticipantRooms,
  selectRoomMessages
} from "@features/Messaging/messagingSlice";
import { socketJoinRoom } from "@features/sockets/socket-events";
import { joinRoom } from "@features/sockets/socketSlice";
import { LabMember } from "@interfaces/lab";
import {
  Avatar,
  Badge,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@mui/material";
import { RootState } from "src/services/store";
import { AppDispatch } from "src/services/store";

type Message = {
  sender: LabMember;
  sender_id: string;
  sender_first_name: string;
  sender_last_name: string;
  avatarUrl?: string;
  content: string;
  time: string;
  created_at: string;
};

type Room = {
  id: string;
  room_type: string;
  creator_id: string;
  creator: {
    first_name: string;
    last_name: string;
  };
  participant: {
    first_name: string;
    last_name: string;
  };
  participants: [];
  last_message: Message;
  unread_count: number;
};
interface ListProps {
  setShowMessageList: (newData: boolean) => void;
}

// eslint-disable-next-line react/display-name
const MessageList: React.FC<ListProps> = React.memo(
  // eslint-disable-next-line react/prop-types
  ({ setShowMessageList }) => {
    const dispatch = useDispatch<AppDispatch>();
    const participantRooms = useSelector(selectParticipantRooms);
    const roomMessages = useSelector(selectRoomMessages);
    const currentUser = useSelector(getUser);
    const newMessages = useSelector(
      (state: RootState) => state.socket.messages
    );

    const [availableRooms, setAvailableRooms] = useState<Room[]>([]);

    const handleAvailableRoomClick = (room_id: string) => {
      dispatch(
        markMessagesAsRead({ readerId: currentUser.id, roomId: room_id })
      );

      dispatch(socketJoinRoom(room_id));

      dispatch(joinRoom(room_id));

      dispatch(getRoomMessages({ roomId: room_id }));
    };

    useEffect(() => {
      if (currentUser?.id) {
        dispatch(getParticipantRooms({ participantId: currentUser.id }));
      }
    }, [dispatch, currentUser, newMessages, roomMessages]);

    useEffect(() => {
      if (participantRooms && participantRooms.length > 0) {
        setAvailableRooms(participantRooms);
      }
    }, [participantRooms, roomMessages, newMessages]);

    const memoizedRoomList = useMemo(
      () =>
        availableRooms?.map((msg, index) => (
          <ListItem
            key={index}
            sx={{ width: { xs: "auto", sm: "100%" } }}
            onClick={() => {
              handleAvailableRoomClick(msg.id);
              setShowMessageList(false);
            }}
            button
          >
            <ListItemAvatar>
              <Avatar src='' />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  {msg?.creator_id === currentUser.id ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant='body1' sx={{ marginRight: "20px" }}>
                        {`${msg?.participant.first_name} ${msg?.participant.last_name}`}
                      </Typography>
                      {msg?.unread_count > 0 && (
                        <Badge
                          badgeContent={msg?.unread_count}
                          color='error'
                          sx={{
                            "& .MuiBadge-badge": {
                              backgroundColor: "#209498",
                              color: "white",
                              fontSize: "0.65rem",
                              minWidth: "16px",
                              height: "16px",
                              padding: "0 4px"
                            }
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant='body1'
                        sx={{ marginRight: "20px", textOverflow: "ellipsis" }}
                      >
                        {`${msg?.creator.first_name} ${msg?.creator.last_name}`}
                      </Typography>
                      {msg?.unread_count > 0 && (
                        <Badge
                          badgeContent={msg?.unread_count}
                          sx={{
                            "& .MuiBadge-badge": {
                              backgroundColor: "#209498",
                              color: "white",
                              fontSize: "0.65rem",
                              minWidth: "16px",
                              height: "16px",
                              padding: "0 4px"
                            }
                          }}
                        />
                      )}
                    </Box>
                  )}
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    sx={{
                      fontSize: "0.75rem",
                      marginRight: "20px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "50px"
                    }}
                  >
                    {msg?.last_message?.created_at &&
                      new Date(msg.last_message.created_at).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false
                        }
                      )}
                  </Typography>
                </Box>
              }
              secondary={`${msg?.last_message?.sender?.user_id === currentUser?.id ? "You" : msg?.last_message?.sender?.first_name}: ${msg?.last_message?.content}`}
              secondaryTypographyProps={{
                sx: {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px"
                }
              }}
            />
          </ListItem>
        )),
      [availableRooms, currentUser.id, handleAvailableRoomClick]
    );

    return (
      <List
        sx={{
          flexDirection: "row",
          overflowX: "auto",
          whiteSpace: "nowrap",
          padding: "none"
        }}
      >
        {memoizedRoomList.length > 0 ? (
          memoizedRoomList
        ) : (
          <Typography sx={{ paddingLeft: "16px" }}>
            No recent conversations
          </Typography>
        )}
      </List>
    );
  }
);
export default MessageList;
