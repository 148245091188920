import { useContext } from "react";

import DashboardContext from "@contexts/DashboardContext";

const useNotifications = () => {
  const { notifications } = useContext(DashboardContext);

  return notifications;
};

export default useNotifications;
