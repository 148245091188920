/* eslint-disable @typescript-eslint/no-explicit-any */
import getError from "@features/utils/get-error";
import { Notification } from "@interfaces/notifications";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import api from "src/services/api";
import { RootState } from "src/services/store";

// Async thunk for fetching unread notifications
export const fetchUnreadNotifications = createAsyncThunk(
  "notifications/fetchUnread",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/notifications/unread");
      return response.data.data;
    } catch (error) {
      const errors = getError(error);
      return rejectWithValue(errors);
    }
  }
);

// Async thunk for fetching all notifications
export const fetchAllNotifications = createAsyncThunk(
  "notifications/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/notifications");
      return response.data.data;
    } catch (error) {
      const errors = getError(error);
      return rejectWithValue(errors);
    }
  }
);

// Async thunk for marking a notification as read
export const markNotificationAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async (notificationId: string, { rejectWithValue }) => {
    try {
      const response = await api.patch(`/notifications/${notificationId}`);
      return { id: notificationId, ...response.data };
    } catch (error) {
      const errors = getError(error);
      return rejectWithValue(errors);
    }
  }
);

// Async thunk for updating notification preferences
export const updateNotificationPreferences = createAsyncThunk(
  "notifications/updatePreferences",
  async (preferences: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await api.put("/notifications/preferences", preferences);
      return response.data;
    } catch (error) {
      const errors = getError(error);
      return rejectWithValue(errors);
    }
  }
);

export const fetchNotificationPreferences = createAsyncThunk(
  "notifications/fetchPreferences",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/notifications/preferences");
      return response.data.data.preferences;
    } catch (error) {
      const errors = getError(error);
      return rejectWithValue(errors);
    }
  }
);

interface InitialState {
  unreadNotifications: Notification[];
  allNotifications: Notification[];
  preferences: Record<string, any>;
  loading: {
    fetchUnread: boolean;
    fetchAll: boolean;
    updatePreferences: boolean;
    fetchPreferences: boolean;
  };
  error: any;
}

const initialState: InitialState = {
  unreadNotifications: [],
  allNotifications: [],
  preferences: {},
  loading: {
    fetchUnread: false,
    fetchAll: false,
    updatePreferences: false,
    fetchPreferences: false
  },
  error: null
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.allNotifications.push(action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch Unread Notifications
      .addCase(fetchUnreadNotifications.pending, (state) => {
        state.loading.fetchUnread = true;
      })
      .addCase(fetchUnreadNotifications.fulfilled, (state, action) => {
        state.unreadNotifications = action.payload.notifications;
        state.loading.fetchUnread = false;
        state.error = null;
      })
      .addCase(fetchUnreadNotifications.rejected, (state, action) => {
        state.loading.fetchUnread = false;
        state.error = action.payload;
      })

      // Fetch All Notifications
      .addCase(fetchAllNotifications.pending, (state) => {
        state.loading.fetchAll = true;
      })
      .addCase(fetchAllNotifications.fulfilled, (state, action) => {
        state.allNotifications = action.payload.notifications;
        state.loading.fetchAll = false;
        state.error = null;
      })
      .addCase(fetchAllNotifications.rejected, (state, action) => {
        state.loading.fetchAll = false;
        state.error = action.payload;
      })

      // Mark Notification as Read
      .addCase(markNotificationAsRead.fulfilled, (state, action) => {
        const index = state.unreadNotifications.findIndex(
          (n) => n.id === action.payload.id
        );
        if (index !== -1) {
          state.unreadNotifications.splice(index, 1);
        }
        const allIndex = state.allNotifications.findIndex(
          (n) => n.id === action.payload.id
        );
        if (allIndex !== -1) {
          state.allNotifications[allIndex].is_read = true;
        }
      })

      // Update Notification Preferences
      .addCase(updateNotificationPreferences.fulfilled, (state, action) => {
        state.preferences = action.payload.data.preferences;
      })
      .addCase(fetchNotificationPreferences.pending, (state) => {
        state.loading.fetchPreferences = true;
      })
      .addCase(fetchNotificationPreferences.fulfilled, (state, action) => {
        state.preferences = action.payload;
        state.loading.fetchPreferences = false;
        state.error = null;
      })
      .addCase(fetchNotificationPreferences.rejected, (state, action) => {
        state.loading.fetchPreferences = false;
        state.error = action.payload;
      });
  }
});

const { name, actions } = notificationsSlice;

export const { addNotification } = actions;

const getSlice = (state: RootState) => state[name];

export const selectNotificationPreferences = createSelector(
  getSlice,
  (slice) => slice?.preferences || {}
);
export const selectUnreadNotifications = createSelector(
  getSlice,
  (slice) => slice?.unreadNotifications || {}
);
export const selectAllNotifications = createSelector(
  getSlice,
  (slice) => slice?.allNotifications || {}
);
export const selectNotificationError = createSelector(
  getSlice,
  (slice) => slice?.error || null
);
export const selectNotificationsLoading = createSelector(
  getSlice,
  (slice) =>
    slice?.loading || {
      fetchUnread: false,
      fetchAll: false,
      updatePreferences: false,
      fetchPreferences: false
    }
);
export const selectUnreadCount = createSelector(
  selectAllNotifications,
  (notifications) => notifications?.filter((msg) => !msg.is_read)?.length || 0
);
