import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      // Using a small timeout to wait until the page is fully rendered
      setTimeout(() => {
        const element = document.getElementById(location.hash.substring(1)); // removing the hashtag
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500); // A small delay
    }
  }, [location]);

  return null;
};

export default ScrollToHashElement;
