import { Message } from "@interfaces/messaging";
import { Notification } from "@interfaces/notifications";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SocketState {
  isConnected: boolean;
  currentRoom: string | null;
  notifications: Notification[];
  messages: Message[];
  typingUsers: string[];
}

const initialState: SocketState = {
  isConnected: false,
  currentRoom: null,
  notifications: [],
  messages: [],
  typingUsers: []
};

export const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    socketConnected: (state) => {
      state.isConnected = true;
    },
    socketDisconnected: (state) => {
      state.isConnected = false;
    },
    joinRoom: (state, action: PayloadAction<string>) => {
      state.currentRoom = action.payload;
    },
    leaveRoom: (state) => {
      state.currentRoom = null;
    },
    messageReceived: (state, action: PayloadAction<Message>) => {
      state.messages.push(action.payload);
    },
    addTypingUser: (state, action: PayloadAction<string>) => {
      if (!state.typingUsers.includes(action.payload)) {
        state.typingUsers.push(action.payload);
      }
    },
    removeTypingUser: (state, action: PayloadAction<string>) => {
      state.typingUsers = state.typingUsers.filter(
        (user) => user !== action.payload
      );
    }
  }
});

export const {
  socketConnected,
  socketDisconnected,
  joinRoom,
  leaveRoom,
  messageReceived,
  addTypingUser,
  removeTypingUser
} = socketSlice.actions;
