import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useHandleSearchSubmit } from "@hooks/useHandleSearchSubmit";
import SearchIcon from "@mui/icons-material/Search";
import { List, ListItem, ListItemText, Paper, Typography } from "@mui/material";

import { Search, SearchIconWrapper, StyledInputBase } from "./StyledComponents";

const SearchBar: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const handleSearchSubmit = useHandleSearchSubmit(setSearchTerm);

  const options = [
    { text: `${searchTerm} in Public feed`, page: "dashboard/home" },
    { text: `${searchTerm} in Institution feed`, page: "dashboard" },
    {
      text: `${searchTerm} in Institution database`,
      page: "dashboard/institution-database"
    },
    {
      text: `${searchTerm} in Lab and People`,
      page: "dashboard/institution-search"
    }
  ];

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    setShowDropdown(inputValue.length > 0);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setShowDropdown(false);
      handleSearchSubmit(searchTerm, event);
    }
  };

  const handleOptionClick = (page: string) => {
    if (page === "dashboard" || page === "dashboard/home") {
      navigate(`/${page}`);
      handleSearchSubmit(searchTerm);
    } else {
      const encodedSearchTerm = searchTerm.replace(/ /g, "+");
      navigate(`/${page}?query=${encodedSearchTerm}`);
    }
    setShowDropdown(false);
  };

  const highlightText = (text: string) => {
    if (!searchTerm) return text;
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <Typography
          key={index}
          component='span'
          fontWeight='bold'
          style={{ margin: "0 2px" }}
        >
          {part}
        </Typography>
      ) : (
        <Typography key={index} component='span'>
          {part}
        </Typography>
      )
    );
  };

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon color='primary' />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder='Search for tissues, animal models, and more'
        value={searchTerm}
        onChange={handleSearch}
        onKeyPress={handleKeyPress}
      />
      {showDropdown && (
        <Paper
          style={{
            position: "absolute",
            top: "100%",
            width: "100%",
            zIndex: 1
          }}
        >
          <List>
            {options.map((option, index) => (
              <ListItem
                key={index}
                button
                onClick={() => handleOptionClick(option.page)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <SearchIcon color='primary' />
                <ListItemText
                  primary={highlightText(option.text)}
                  primaryTypographyProps={{
                    style: { display: "flex", alignItems: "center" }
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Search>
  );
};

export default SearchBar;
